import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {httpHostname} from '@app/utils/settings';
import apiRequest from "@app/utils/axios";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import * as Yup from "yup";
import {completedRegistration, unConfirmRegistration} from '@store/reducers/auth';
import Header from "@modules/guest/Header";
import Title from "@modules/guest/Title";
import {Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faMobileAlt, faUserSecret} from "@fortawesome/free-solid-svg-icons";
import CountrySelect from "@app/components/select/CountrySelect";
import AsyncSelect from "react-select/async";
import {selectCodeValue} from "@app/services/dataloader";
import {Button, Checkbox} from "@components";

const CompleteRegistration = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useruuid } = useParams();
  const isResellerGeneral = (process.env.REACT_APP_RESELLER_URL === httpHostname);

  const register = async (values: object, actions: any) => {
    setAuthLoading(true);
    actions.setSubmitting(true);
    await apiRequest(`user/complete_registration`, (response: any) => {
      setAuthLoading(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        toast.success(response.message);
        dispatch(completedRegistration());
        dispatch(unConfirmRegistration());
        navigate(`/confirm-registration/${response.user_uuid}`);
      } else if (response.status === 2) {
          toast.error(response.message);
      }
    }, values, (error: any) => {
      if (error.response.status === 422) {
          actions.setErrors(error.response.data.errors);
      }
      setAuthLoading(false);
      actions.setSubmitting(false);
    });
  };

 const {handleChange, values, handleSubmit, setFieldValue, touched, errors} = useFormik({
    initialValues: {
      username: '',
      phoneNumber: '',
      hearabout: '',
      useruuid,
      agentCode: '',
      terms:false,
    },
    validationSchema: Yup.object({
      username: Yup.string()
            .min(3, t('validation.minimum', {mincount: '3'}))
            .max(12, t('validation.maximum', {maxcount: '12'}))
            .required(t('validation.required')),
      phoneNumber: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(10, t('validation.minimum', {mincount: '10'}))
          .max(13, t('validation.maximum', {maxcount: '13'}))
          .required(t('validation.required')),
      hearabout: Yup.string()
          .required(t('validation.required')),
      agentCode: isResellerGeneral ? Yup.string().max(13, t('validation.maximum', {maxcount: '13'})).required(t('validation.required')) : Yup.string(),
      terms: Yup.bool()
          .oneOf([true], t('register.acceptterms'))
    }),
    onSubmit: register,
 });

  return (
    <>
      <Header/>
      <div className="register-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <Title/>
          </div>
          <div className="card-body">
            <p className="login-box-msg">{t('register.complete')}</p>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <InputGroup className="mb-3">
                      <Form.Control
                        id="username"
                        name="username"
                        type="text"
                        placeholder="^Username"
                        onChange={handleChange}
                        value={values.username}
                        isValid={touched.username && !errors.username}
                        isInvalid={touched.username && !!errors.username}
                      />
                      {touched.username && errors.username ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.username}
                        </Form.Control.Feedback>
                      ) : (
                        <InputGroup.Append>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faAddressCard}/>
                          </InputGroup.Text>
                        </InputGroup.Append>
                      )}
                    </InputGroup>
                  </div>
                </div>
                <div className="col">
                  {isResellerGeneral &&
                  <div className="mb-3">
                    <InputGroup className="mb-3">
                      <Form.Control
                        id="agentCode"
                        name="agentCode"
                        type="text"
                        placeholder="^Agent Code"
                        onChange={handleChange}
                        value={values.agentCode}
                        isValid={touched.agentCode && !errors.agentCode}
                        isInvalid={touched.agentCode && !!errors.agentCode}
                      />
                      {touched.agentCode && errors.agentCode ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.agentCode}
                        </Form.Control.Feedback>
                      ) : (
                        <InputGroup.Append>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUserSecret}/>
                          </InputGroup.Text>
                        </InputGroup.Append>
                      )}
                    </InputGroup>
                  </div>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="phoneNumber"
                      name="phoneNumber"
                      type="text"
                      placeholder="^Phone Number"
                      onChange={handleChange}
                      value={values.phoneNumber}
                      isValid={touched.phoneNumber && !errors.phoneNumber}
                      isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                    />
                    {touched.phoneNumber && errors.phoneNumber ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.phoneNumber}
                      </Form.Control.Feedback>
                    ) : (
                      <InputGroup.Append>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faMobileAlt}/>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    )}
                  </InputGroup>
                </div>
                <div className="col-4">
                  <CountrySelect
                      value="TZ"
                      onChange={(selectedOption: any) => {
                          const event = { target : { name:"code", value: selectedOption.value}};
                          handleChange(event);
                      }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <AsyncSelect
                    isClearable
                    cacheOptions
                    defaultOptions
                    className="mb-3"
                    name="hearabout"
                    loadOptions={(inputValue) => (selectCodeValue( inputValue, 18))}
                    placeholder="^How did you hear about this service"
                    onChange={(selectedOptions: any) => {
                        let event;
                        if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
                            /* multiple selections, map through array */
                            event = { target : { name:'hearabout', value: "input"}}
                        } else if (!Array.isArray(selectedOptions) && selectedOptions) {
                            /* single selection */
                            event = { target : { name:'hearabout', value: selectedOptions.value}}
                        } else {
                            /* no selection */
                            event = { target : { name:'hearabout', value: ""}}
                        }
                        handleChange(event);
                    }}
                />
                {(touched.hearabout && errors.hearabout) && (
                    <Form.Control.Feedback type="invalid">
                      {errors.hearabout}
                    </Form.Control.Feedback>
                )}
              </div>
              <div className="row">
                <div className="col-7">
                  <Checkbox type="icheck" name="terms" onChange={(selected: boolean) => {setFieldValue("terms", selected)}} checked={false}>
                    <span>^I have read and accept the </span>
                    <Link to={{pathname: "https://nextsms.co.tz/terms_and_conditions"}} target="_blank">^ Terms & Conditions</Link>
                  </Checkbox>
                </div>
                <div className="col-5">
                  <Button
                    type="submit"
                    block
                    theme="outline-primary"
                    isLoading={isAuthLoading}
                  >
                    {t('register.label')}
                  </Button>
                </div>
              </div>
              <Link to="/login" className="text-center">
                {t('register.alreadyHave')}
              </Link>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteRegistration;
