import React, {useEffect, useState} from 'react';
/* import {ContentHeader} from '@components'; */
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";
import * as XLSX from 'xlsx';
import {confirmAlert} from "react-confirm-alert";
import apiRequest from "@app/utils/axios";
import {loadPageData} from "@store/reducers/auth";
import {toast} from "react-toastify";
import {Form, Formik, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import {Button} from "@components";
import {Spinner} from "react-bootstrap";
import Select from "react-select";
import parse from 'html-react-parser';
import CountrySelect from "@app/components/select/CountrySelect";
/* import 'react-confirm-alert/src/react-confirm-alert.css'; */

const ImportContactFile = () => {
  const [t] = useTranslation();
  const serverData = useSelector((state: any) => state.auth.data);
  const { state }: {state: any} = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isImportLoading, setImportLoading] = useState(false);
  const [isLoadingFile, setLoadingFile] = useState(false);
  const [selectColumnOptions, setSelectColumnOptions] = useState<any>([]);
  const [jsonFileData, setJsonFileData] = useState<any>([]);
  const {groupuuid, groupname} = state || [];

  const handleContactFileUpload = (e: any) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(selectedFile);
    reader.onerror = (event: any) => {
      console.error(`File could not be read! Code ${event.target.error.code}`);
    };
    reader.onload = (event: any) => {
      setLoadingFile(true);
      const data = event.target.result;
      const workbook = XLSX.read(data, {type:'binary'});
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsondata: any = XLSX.utils.sheet_to_json(worksheet, {raw: false});
      /* console.log(jsondata); */
      setJsonFileData(JSON.stringify(jsondata));
      setSelectColumnOptions(Object.keys(jsondata[0]).map((option: any) => {return {'label': option, 'value': option}}));
      setLoadingFile(false);
    };
  };

  const previewFileContactSummary = (response: any, identity: any) => {
    const options = {
      title: "",
      message: "",
      buttons: [{
        label: t('views.crud.import'),
        onClick: () => {
          /* Resume the registered job for importing contacts ... */
          apiRequest(`contact/upload_file`, (res: any) => {
            toast.success(res.message);
            if (groupuuid) {
              navigate(`/group/view/${groupuuid}`);
            } else {
              navigate('/contact');
            }
          }, {identity, groupuuid});
        }
      }, {
        label: t('views.crud.no'),
        onClick: () => {
          /* Delete the recent registered job for importing contacts ... */
          apiRequest(`contact/cancel_upload_file`, () => {
          }, {identity});
          return false;
        }
      }],
      childrenElement: () => (
        <>
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">{t('views.contact.imsum')}</h3>
            </div>
            <div className="card-body">
              <table className="table table-hover table-striped">
                <thead>
                <tr>
                  <th scope="col">{' '}</th>
                  <th scope="col">{t('views.label.value')}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">{t('views.contact.duplicate')}</th>
                  <td>{response.duplicates}</td>
                </tr>
                <tr>
                  <th scope="row">{t('views.contact.unique')}</th>
                  <td>{response.unique}</td>
                </tr>
                <tr>
                  <th scope="row">{t('views.contact.invalid')}</th>
                  <td>{response.invalid}</td>
                </tr>
                <tr>
                  <th scope="row">{t('views.contact.valid')}</th>
                  <td>{response.valid}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className="card-footer">
              {t('views.contact.totalu', {'count': response.unique})}
            </div>
          </div>
        </>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name"
    };
    confirmAlert(options);
  };
  const postImportFileContact = async (values: object, actions: any) => {
    setImportLoading(true);
    actions.setSubmitting(true);
    await apiRequest(`contact/post_import_file`, (response: any) => {
      setImportLoading(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        previewFileContactSummary(response.summary, response.identity);
      } else if (response.status === 3) {
        toast.error(response.message);
      }
    }, {...values, "json_data" : jsonFileData, "preview": 1}, (error: any) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
      setImportLoading(false);
      actions.setSubmitting(false);
    });
  };

  const ImportContactFileSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(t('validation.required')),
    last_name: Yup.string()
      .required(t('validation.required')),
    phone_number: Yup.string()
      .required(t('validation.required')),
  });

  useEffect(() => {
    apiRequest(`contact/get_import_file`, (response: any) => {
      dispatch(loadPageData(response));
    });
    return () => {

    };
  }, []);

  return (
    <div>
      {/* <ContentHeader title="Blank Page" /> */}
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{groupname ? parse(`${t('views.contact.gimports')} <b>${groupname}</b>`) : t('views.contact.imports')}</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus" />
                </button>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="remove"
                  data-toggle="tooltip"
                  title="Remove"
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{}}
                validationSchema={ImportContactFileSchema}
                onSubmit={postImportFileContact}
              >
                {({ handleChange}) => (
                  <Form>
                    <div className="row">
                      <div className="offset-3 col-6">
                        <div className="form-group">
                          <div className="strike"><span>{t('views.contact.filed')}</span></div>
                          <Field
                            title={t('views.contact.file')}
                            type="file"
                            name="contact_file"
                            accept=".csv, .xls, .xlsx"
                            className="btn btn-outline-secondary btn-block"
                            onChange={handleContactFileUpload}
                            disabled={isLoadingFile}
                          />
                          {isLoadingFile && (
                            <>
                              <Spinner
                                className="ml-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              {' '}
                              {t("views.contact.loading")}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="strike"><span>{t('views.contact.mapping')}</span></div>
                    <div className="row">
                      <div className="offset-3 col-3">
                        {serverData.attributes && serverData.attributes.slice(0, Math.ceil(serverData.attributes.length/2)).map((attribute: any) => (
                          <div className="form-group" key={attribute.id}>
                            <Field as="label" className={`${attribute.important ? 'required' : ''}`}>{attribute.value}</Field>
                            <div style={{'display': 'block', 'width': '100%'}}>
                              <Select
                                name={attribute.id}
                                styles={{control: (styles) => ({...styles, width: attribute.id === 'phone_number' ? '60%' : '100%', float: 'left'})}}
                                placeholder={attribute.value}
                                options={selectColumnOptions}
                                onChange={(selectedOption: any) => {
                                  const event = { target : { name:attribute.id, value: selectedOption.value}};
                                  handleChange(event);
                                }}
                              />
                              {attribute.id === 'phone_number' && (
                                <>
                                  <CountrySelect
                                    value="TZ"
                                    onChange={(selectedOption: any) => {
                                      const event = { target : { name:"code", value: selectedOption.value}};
                                      handleChange(event);
                                    }}
                                    width="40%"
                                  />
                                </>
                              )}
                            </div>
                            <ErrorMessage className="error invalid-feedback-form" component="span" name={attribute.id} />
                          </div>
                        ))}
                      </div>
                      <div className="col-3">
                        {serverData.attributes && serverData.attributes.slice(Math.ceil(serverData.attributes.length/2), serverData.attributes.length).map((attribute: any) => (
                          <div className="form-group" key={attribute.id}>
                            <Field as="label" className={`${attribute.important ? 'required' : ''}`}>{attribute.value}</Field>
                            <Select
                              name={attribute.id}
                              options={selectColumnOptions}
                              placeholder={attribute.value}
                              onChange={(selectedOption: any) => {
                                const event = { target : { name:attribute.id, value: selectedOption.value}};
                                handleChange(event);
                              }}
                            />
                            <ErrorMessage className="error invalid-feedback-form" component="span" name={attribute.id} />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="offset-3 col-2">
                        <Link
                          to={{pathname: groupuuid ? `/group/view/${groupuuid}` : "/contact"}}
                          className="btn btn-outline-danger"
                        >
                          <span>{t('views.label.cancel')}</span>
                        </Link>
                      </div>
                      <div className="col-4">
                        <Button
                          block
                          type="submit"
                          theme="outline-primary"
                          isLoading={isImportLoading}
                        >
                          {t('views.contact.import')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="card-footer">&nbsp;</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ImportContactFile;
