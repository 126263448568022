import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button} from '@components';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import {removeWindowClass, setWindowClass} from '@app/utils/helpers';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Form, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useDispatch} from "react-redux";
import apiRequest from "@app/utils/axios";
import {loginUser} from "@store/reducers/auth";
import Title from "@modules/guest/Title";

const RecoverPassword = () => {
  const [isPasswordRecovering, setPasswordRecovering] = useState(false);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uid, token } = useParams();

  const recoverPassword = async (values: object, actions: any) => {
    setPasswordRecovering(true);
    actions.setSubmitting(true);
    await apiRequest(`user/recover_password`, (response: any) => {
      setPasswordRecovering(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        removeWindowClass('login-page');
        removeWindowClass('hold-transition');
        dispatch(loginUser({'token': response.uuid}));
        navigate(`/`);
        toast.success(response.message);
      } else if (response.status === 2) {
          toast.error(response.message);
      }
    }, values, (error: any) => {
      if (error.response.status === 422) {
          actions.setErrors(error.response.data.errors);
      }
      setPasswordRecovering(false);
      actions.setSubmitting(false);
    });
  };

  const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      password: '',
      uid,
      token
    },
    validationSchema: Yup.object({
      password: Yup.string()
            .min(6, t('validation.minimum', {mincount: '6'}))
            .max(12, t('validation.maximum', {maxcount: '12'}))
            .required(t('validation.required')),
    }),
    onSubmit: recoverPassword,
  });

  setWindowClass('hold-transition login-page');

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
            <Title />
        </div>
        <div className="card-body">
          <p className="login-box-msg">{t('recover.oneStepAway')}</p>
          <form onSubmit={handleSubmit}>
            <Form.Control name='token' type="hidden" />
            <Form.Control name='uid' type="hidden" />
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="password"
                  name="password"
                  type="password"
                  placeholder={t('register.password')}
                  onChange={handleChange}
                  value={values.password}
                  isValid={touched.password && !errors.password}
                  isInvalid={touched.password && !!errors.password}
                />
                {touched.password && errors.password ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faLock} />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </div>
            <div className="row">
              <div className="col-12">
                <Button
                  type="submit"
                  block
                  theme="outline-primary"
                  isLoading={isPasswordRecovering}
                >
                  {t('recover.changePassword')}
                </Button>
              </div>
            </div>
          </form>
          <p className="mt-3 mb-1">
            <Link to="/login">{t('login.button.signIn.label')}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
