import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {unConfirmRegistration, completeRegistration} from '@store/reducers/auth';
import {Button, Checkbox} from '@components';
import {faAddressCard, faKey, faMobileAlt, faUser, faUserSecret} from '@fortawesome/free-solid-svg-icons';
import {setWindowClass} from '@app/utils/helpers';
import {Form, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Title from "@modules/guest/Title";
import {httpHostname} from '@app/utils/settings';
import Header from "@modules/guest/Header";
import CountrySelect from "@app/components/select/CountrySelect";
import AsyncSelect from 'react-select/async';
import {selectCodeValue} from "@app/services/dataloader";
/* import GoogleLogin from "react-google-login"; */
import { GoogleLogin } from '@react-oauth/google';
import apiRequest from '@app/utils/axios';

const Register = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
  const isResellerGeneral = (process.env.REACT_APP_RESELLER_URL === httpHostname);
  const needGoogleAuth = (process.env.REACT_APP_RESELLER_URL === httpHostname || process.env.REACT_APP_CUSTOMER_URL === httpHostname);
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const registerAccount = async (values: object, actions: any) => {
    /* console.log(JSON.stringify(values)); */
    setAuthLoading(true);
    actions.setSubmitting(true);
    await apiRequest(`user/register`, (response: any) => {
      setAuthLoading(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        toast.success(response.message);
        dispatch(unConfirmRegistration());
        navigate(`/confirm-registration/${response.user_uuid}`);
      } else if (response.status === 2) {
        toast.error(response.message);
      }
    }, values, (error: any) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
      setAuthLoading(false);
      actions.setSubmitting(false);
    });
  };

  /* const registerByGoogle: any = useGoogleLogin({
    onSuccess: async (response: any) => {
      /!* console.log(response) *!/
      await apiRequest(`user/register_provider`, (response: any) => {
        setGoogleAuthLoading(false);
        if (response.status === 0) {
          toast.success(t('register.authenticated'));
          dispatch(completeRegistration());
          navigate(`/complete-registration/${response.user_uuid}`);
        } else if (response.status === 33) {
          toast.success(response.message);
          setAuthLoading(false);
          dispatch(unConfirmRegistration());
          navigate(`/confirm-registration/${response.user_uuid}`);
        } else {
          toast.error(response.message);
        }
      }, {token: response.access_token}, () => {
        setGoogleAuthLoading(false);
      });
    },
    onError: () => console.log('Login Failed'),
    /!* flow: "auth-code" *!/
  }); */

  const registerByGoogle = async (response: any) => {
    /* console.log(response); */
    await apiRequest(`user/register_provider`, (response: any) => {
      setGoogleAuthLoading(false);
      if (response.status === 0) {
        toast.success(t('register.authenticated'));
        dispatch(completeRegistration());
        navigate(`/complete-registration/${response.user_uuid}`);
      } else if (response.status === 33) {
        toast.success(response.message);
        setAuthLoading(false);
        dispatch(unConfirmRegistration());
        navigate(`/confirm-registration/${response.user_uuid}`);
      } else if (response.status === 34) {
        toast.warning(response.message);
      } else {
        toast.error(response.message);
      }
    }, {token: response.credential}, () => {
      setGoogleAuthLoading(false);
    });
  };

  const {handleChange, values, handleSubmit, setFieldValue, touched, errors} = useFormik({
    initialValues: {
      fullName: '',
      username: '',
      password: '',
      passwordRetype: '',
      phoneNumber: '',
      hearabout: '',
      agentCode: '',
      terms: false,
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .min(3, t('validation.minimum', {mincount: '3'}))
        .max(50, t('validation.maximum', {maxcount: '50'}))
        .required(t('validation.required')),
      username: Yup.string()
        .trim(t('validation.whitespace'))
        .strict(true)
        .min(3, t('validation.minimum', {mincount: '3'}))
        .max(12, t('validation.maximum', {maxcount: '12'}))
        .required(t('validation.required')),
      password: Yup.string()
        .min(6, t('validation.minimum', {mincount: '6'}))
        .max(28, t('validation.maximum', {maxcount: '12'}))
        .required(t('validation.required')),
      passwordRetype: Yup.string()
        .min(6, '^Must be 6 characters or more')
        .max(28, '^Must be 20 characters or less')
        .required(t('validation.required'))
        .when('password', {
          is: (val: string) => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            '^Both password need to be the same'
          )
        }),
      phoneNumber: Yup.string()
        .matches(/^[0-9]+$/, "^Must be only digits")
        .min(10, t('validation.minimum', {mincount: '10'}))
        .max(13, t('validation.maximum', {maxcount: '13'}))
        .required(t('validation.required')),
      agentCode: isResellerGeneral ? Yup.string().max(13, t('validation.maximum', {maxcount: '13'})).required(t('validation.required')) : Yup.string(),
      hearabout: Yup.string()
        .required(t('validation.required')),
      terms: Yup.bool()
        .oneOf([true], '^Accept Terms & Conditions is required')
    }),
    onSubmit: registerAccount,
  });

  setWindowClass('hold-transition register-page');

  return (
    <>
      <Header/>
      <div className="register-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <Title/>
          </div>
          <div className="card-body">
            <p className="login-box-msg">{t('register.registerNew')}</p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <InputGroup className="mb-3">
                  <Form.Control
                    id="fullName"
                    name="fullName"
                    type="text"
                    placeholder="^Full Name"
                    onChange={handleChange}
                    value={values.fullName}
                    isValid={touched.fullName && !errors.fullName}
                    isInvalid={touched.fullName && !!errors.fullName}
                  />
                  {touched.fullName && errors.fullName ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.fullName}
                    </Form.Control.Feedback>
                  ) : (
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser}/>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  )}
                </InputGroup>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <InputGroup className="mb-3">
                      <Form.Control
                        id="username"
                        name="username"
                        type="text"
                        placeholder="^Username"
                        onChange={handleChange}
                        value={values.username}
                        isValid={touched.username && !errors.username}
                        isInvalid={touched.username && !!errors.username}
                      />
                      {touched.username && errors.username ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.username}
                        </Form.Control.Feedback>
                      ) : (
                        <InputGroup.Append>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faAddressCard}/>
                          </InputGroup.Text>
                        </InputGroup.Append>
                      )}
                    </InputGroup>
                  </div>
                  <div className="mb-3">
                    <InputGroup className="mb-3">
                      <Form.Control
                        id="password"
                        name="password"
                        type="password"
                        placeholder="^Password"
                        onChange={handleChange}
                        value={values.password}
                        isValid={touched.password && !errors.password}
                        isInvalid={touched.password && !!errors.password}
                      />
                      {touched.password && errors.password ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      ) : (
                        <InputGroup.Append>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faKey}/>
                          </InputGroup.Text>
                        </InputGroup.Append>
                      )}
                    </InputGroup>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <InputGroup className="mb-3">
                      <Form.Control
                        id="passwordRetype"
                        name="passwordRetype"
                        type="password"
                        placeholder="^Retype password"
                        onChange={handleChange}
                        value={values.passwordRetype}
                        isValid={touched.passwordRetype && !errors.passwordRetype}
                        isInvalid={touched.passwordRetype && !!errors.passwordRetype}
                      />
                      {touched.passwordRetype && errors.passwordRetype ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.passwordRetype}
                        </Form.Control.Feedback>
                      ) : (
                        <InputGroup.Append>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faKey}/>
                          </InputGroup.Text>
                        </InputGroup.Append>
                      )}
                    </InputGroup>
                  </div>
                  {isResellerGeneral &&
                  <div className="mb-3">
                    <InputGroup className="mb-3">
                      <Form.Control
                        id="agentCode"
                        name="agentCode"
                        type="text"
                        placeholder="^Agent Code"
                        onChange={handleChange}
                        value={values.agentCode}
                        isValid={touched.agentCode && !errors.agentCode}
                        isInvalid={touched.agentCode && !!errors.agentCode}
                      />
                      {touched.agentCode && errors.agentCode ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.agentCode}
                        </Form.Control.Feedback>
                      ) : (
                        <InputGroup.Append>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUserSecret}/>
                          </InputGroup.Text>
                        </InputGroup.Append>
                      )}
                    </InputGroup>
                  </div>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="phoneNumber"
                      name="phoneNumber"
                      type="text"
                      placeholder="^Phone Number"
                      onChange={handleChange}
                      value={values.phoneNumber}
                      isValid={touched.phoneNumber && !errors.phoneNumber}
                      isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                    />
                    {touched.phoneNumber && errors.phoneNumber ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.phoneNumber}
                      </Form.Control.Feedback>
                    ) : (
                      <InputGroup.Append>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faMobileAlt}/>
                        </InputGroup.Text>
                      </InputGroup.Append>
                    )}
                  </InputGroup>
                </div>
                <div className="col-4">
                  <CountrySelect
                    value="TZ"
                    onChange={(selectedOption: any) => {
                      const event = { target : { name:"code", value: selectedOption.value}};
                      handleChange(event);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <AsyncSelect
                    isClearable
                    cacheOptions
                    defaultOptions
                    className="mb-3"
                    name="hearabout"
                    loadOptions={(inputValue) => (selectCodeValue( inputValue, 18))}
                    placeholder="^How did you hear about this service"
                    onChange={(selectedOptions: any) => {
                      let event: any;
                      if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
                        /* multiple selections, map through array */
                        event = { target : { name:'hearabout', value: "input"}}
                      } else if (!Array.isArray(selectedOptions) && selectedOptions) {
                        /* single selection */
                        event = { target : { name:'hearabout', value: selectedOptions.value}}
                      } else {
                        /* no selection */
                        event = { target : { name:'hearabout', value: ""}}
                      }
                      handleChange(event);
                    }}
                  />
                  {(touched.hearabout && errors.hearabout) && (
                    <Form.Control.Feedback type="invalid">
                      {errors.hearabout}
                    </Form.Control.Feedback>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-7">
                  <Checkbox type="icheck" name="terms" onChange={(selected: boolean) => {setFieldValue("terms", selected)}} checked={false}>
                    <span>^I have read and accept the </span>
                    <Link to={{pathname: "https://nextsms.co.tz/terms_and_conditions"}} target="_blank">^ Terms & Conditions</Link>
                  </Checkbox>
                </div>
                <div className="col-5">
                  <Button
                    type="submit"
                    block
                    theme="outline-primary"
                    isLoading={isAuthLoading}
                    disabled={isGoogleAuthLoading}
                  >
                    {t('register.label')}
                  </Button>
                </div>
              </div>
            </form>
            {/* TODO: remove direct access of google authentication ...  */}
            {(needGoogleAuth || true) &&
              <div className="social-auth-links text-center">
                {/* <GoogleLogin
                  clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                  buttonText={t('login.button.signUp.social', {what: 'Google'})}
                  theme='dark'
                  disabled={isAuthLoading || isGoogleAuthLoading}
                  onSuccess={registerByGoogle}
                  onFailure={registerByGoogle}
                  cookiePolicy='single_host_origin'
                /> */}
                {/* <Button
                  block
                  icon="google"
                  theme="outline-danger"
                  onClick={() => registerByGoogle()}
                  isLoading={isGoogleAuthLoading}
                >
                  {t('login.button.signUp.social', {what: 'Google'})}
                </Button> */}
                <GoogleLogin
                  onSuccess={registerByGoogle}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                  useOneTap
                  text="signup_with"
                  size="medium"
                  theme="outline"
                  logo_alignment="center"
                />
              </div>
            }
            <Link to="/login" className="text-center">
              {t('register.alreadyHave')}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
