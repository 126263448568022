import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {MenuItem} from '@components';
import {useTranslation} from "react-i18next";

export interface IMenuItem {
  name: string;
  icon?: string;
  class?: string;
  path?: string;
  children?: Array<IMenuItem>;
}

export const MENU: IMenuItem[] = [
  {
    name: 'views.sms.send',
    path: '/sms/compose',
    icon: 'fa-envelope',
    class: 'btn-send-sms',
  },
  {
    name: 'views.contact.add',
    path: '/contact/add',
    icon: 'fa-user-plus',
    class: '',
  },
  {
    name: 'views.group.manage',
    path: '/group',
    icon: 'fas fa-users',
    class: '',
  },
  {
    name: 'views.payment.recharge',
    path: '/payment/purchase',
    icon: 'fa fa-money-check-alt',
    class: 'btn-recharge-sms',
  },
  {
    name: 'views.sender.registration',
    path: '/senders',
    icon: 'fa fa-id-card-alt',
    class: '',
  },
  {
    name: 'views.sms.file2sms',
    path: '/file_schedule',
    icon: 'fa fa-clock',
    class: '',
  },
  {
    name: 'header.label.api',
    path: '/about',
    icon: 'fa fa-code',
    class: '',
  },
  {
    name: 'views.label.about',
    path: '/about',
    icon: 'fa fa-info-circle',
    class: '',
  },
  /* {
    name: 'menusidebar.label.mainMenu',
    children: [
      {
        name: 'menusidebar.label.subMenu',
        path: '/sub-menu-1',
        icon: 'fa-tachometer-alt',
      },
      {
        name: 'menusidebar.label.blank',
        path: '/sub-menu-2',
        icon: 'fa-tachometer-alt',
      }
    ]
  } */
];

const MenuSidebar = () => {
  const user = useSelector((state: any) => state.auth.currentUser);
  const sidebarSkin = useSelector((state: any) => state.ui.sidebarSkin);
  const menuItemFlat = useSelector((state: any) => state.ui.menuItemFlat);
  const menuChildIndent = useSelector((state: any) => state.ui.menuChildIndent);
  const [t] = useTranslation();

  return (
    <aside className={`main-sidebar elevation-4 ${sidebarSkin}`}>
      <Link to="/" className="brand-link">
        <img
          src="/img/logo-ms.jpg"
          alt="Messaging Service"
          className="brand-image img-circle elevation-3"
          style={{opacity: '.8'}}
        />
        <span className="brand-text font-weight-light">Messaging Service</span>
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src={user.picture || '/img/default-profile.png'}
              className="img-circle elevation-2"
              alt="User"
            />
          </div>
          <div className="info">
            <Link to="/profile" className="d-block">
              {user.username || 'Username'} ({t('header.user.profile')})
            </Link>
          </div>
        </div>
        <nav className="mt-2" style={{overflowY: 'hidden'}}>
          <ul
            className={`nav nav-pills nav-sidebar flex-column${
              menuItemFlat ? ' nav-flat' : ''
            }${menuChildIndent ? ' nav-child-indent' : ''}`}
            role="menu"
          >
            {MENU.map((menuItem: IMenuItem) => (
              <MenuItem key={menuItem.name} menuItem={menuItem} />
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MenuSidebar;
