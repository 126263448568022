import axios, {AxiosRequestConfig} from 'axios';
import store from '@store/store';
import {apiUrl} from '@app/utils/settings';
import {dismissLoading, loadingError, logoutUser, showLoading} from '@store/reducers/auth';
import {toast} from "react-toastify";

let defaultTimeout = 30000;
if (process.env.PROD === 'production') {
  defaultTimeout = 15000;
}

const instance = axios.create({
  baseURL: `${apiUrl}`,
  withCredentials: true,
});
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = "X-CSRFTOKEN";
instance.defaults.timeout = defaultTimeout;
instance.interceptors.request.use(
  (request: AxiosRequestConfig<any>) => {
    store.dispatch(showLoading());
    const {token} = store.getState().auth;
    if (token) {
      request.headers = {...request.headers, Authorization: `Bearer ${token}`, Token: token};
    }
    request.data.source = 1;
    return request;
  },
  (error) => {
    store.dispatch(loadingError());
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    store.dispatch(dismissLoading());
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(logoutUser());
    } else if (error.response.status === 422) {
      store.dispatch(dismissLoading());
    } else {
      store.dispatch(loadingError());
    }
    return Promise.reject(error);
  }
);

const apiRequest = (uriPath: string, successCallback: Function, data: object = {}, errorCallback: Function = () => {}) => {
  return instance.post(`${apiUrl}/${uriPath}/`, data).then((response: any) => {
    return successCallback(response.data);
  }).catch((error: any) => {
    /* Error */
    toast.error(
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      'Failed'
    );
    if (error.response) {

      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      /* console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers); */
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      /* console.log(error.request); */
    } else {
      /* Something happened in setting up the request and triggered an Error */
      /* console.log('Error', error.message); */
    }
    return errorCallback(error);
  });
};

export const asyncApiRequest = async (uriPath: string, successCallback: Function, data: object = {}, errorCallback: Function = () => {}) => {
  const res: any = await instance.post(`${apiUrl}/${uriPath}/`, data).then((response: any) => {
    return successCallback(response.data);
  }).catch((error: any) => {
    toast.error(
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      'Failed'
    );
    return errorCallback(error);
  });
  return res
};

export const fetchRequest = (uriPath: string, successCallback: Function) => {
  fetch(`${apiUrl}/${uriPath}/`, {
      credentials: 'include'
  })
  .then(response => response.json())
  .then(response => {
      successCallback(response);
  });
};

export default apiRequest;
