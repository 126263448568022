import React, {ChangeEventHandler, FormEventHandler, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import apiRequest from "@app/utils/axios";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, DatatableRemote, InputText} from "@components";
import {faBook} from "@fortawesome/free-solid-svg-icons";
/* import 'react-confirm-alert/src/react-confirm-alert.css'; */

const ContactGroupIndex = () => {
  const [isAddLoading, setAddLoading] = useState(false);
  const [updateDt, setUpdateDt] = useState(false);
  const [t] = useTranslation();
  const columns = [
    {
      name: t('views.group.name'),
      cell: (cols: any) => <div>{cols.name}&nbsp;<span className="badge badge-secondary">{t('views.group.contacts', {'n': cols.contacts})}</span></div>,
      selector: (row: any) => row.name,
    },
    {
      name: t('views.group.description'),
      selector: (row: any) => row.description,
    },
    {
      name: t('views.label.create_date'),
      selector: (row: any) => row.create_date,
    },
    {
      name: t('views.button.action'),
      cell: (cols: any) => <Link to={`/group/view/${cols.uuid}`} className="btn btn-sm btn-send-sms"><i className="fas fa-external-link-alt fa-fw"/> {t('views.label.open')}</Link>,
    },
  ];
  const RegisterContactGroup = async (values: object, actions: any) => {
    setAddLoading(true);
    actions.setSubmitting(true);
    await apiRequest(`contact/post_group_create`, (response: any) => {
      setAddLoading(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        toast.success(response.message);
        setUpdateDt(!updateDt);
        actions.resetForm();
      } else if (response.status === 3) {
        toast.error(response.message);
      }
    }, values, (error: any) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
      setAddLoading(false);
      actions.setSubmitting(false);
    });
  };
  const {handleChange, values, handleSubmit, touched, errors}: {handleChange: ChangeEventHandler, values: any, handleSubmit: FormEventHandler<any>, touched: any, errors: any} = useFormik({
    initialValues: {
      'group': '',
      'description': ''
    },
    validationSchema: Yup.object({
      group: Yup.string()
        .required(t('validation.required')),
    }),
    onSubmit: RegisterContactGroup,
  });


  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{t('views.group.index')}</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus" />
                </button>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="remove"
                  data-toggle="tooltip"
                  title="Remove"
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <p>{t('views.group.add')}</p>
              <div className="row">
                <div className="col-12">
                  <form onSubmit={handleSubmit} className="form-inline mb-3">
                    <div className="form-group mx-sm-2">
                      <InputText
                        title={t('views.group.name')}
                        name="group"
                        handleChange={handleChange}
                        value={values.group}
                        touched={touched.group}
                        error={errors.group}
                        icon={faBook}
                      />
                    </div>
                    <div className="form-group mx-sm-1">
                      <InputText
                        title={t('views.group.description')}
                        name="description"
                        handleChange={handleChange}
                        value={values.description}
                        touched={touched.description}
                        error={errors.description}
                        icon={faBook}
                      />
                    </div>
                    <Button
                      type="submit"
                      theme="outline-primary"
                      isLoading={isAddLoading}
                      className="mb-3"
                    >
                      {t('views.crud.add')}
                    </Button>
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <DatatableRemote
                    title="Contact Groups"
                    columns={columns}
                    params={{"datatable": "contact/group_list", "delete":"contact/post_group_delete"}}
                    updateDt={updateDt}
                    buttonSelector="contact_groups"
                  />
                </div>
              </div>

            </div>
            <div className="card-footer">&nbsp;</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactGroupIndex;
