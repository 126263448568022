import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import apiRequest from "@app/utils/axios";
import {toast} from "react-toastify";
import {removeWindowClass, setWindowClass} from "@app/utils/helpers";
import {confirmRegistration, loginUser} from '@store/reducers/auth';
import {useFormik} from "formik";
import * as Yup from "yup";
import Header from "@modules/guest/Header";
import Title from "@modules/guest/Title";
import {Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullseye} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@components";

const ConfirmRegistration = () => {
  const [isConfirmLoading, setConfirmLoading] = useState(false);
  const [isResendConfirmLoading, setResendConfirmLoading] = useState(false);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useruuid } = useParams();

  const submitConfirmRegistration = async (values: object, actions: any) => {
    setConfirmLoading(true);
    actions.setSubmitting(true);
    await apiRequest(`user/confirm_account`, (response: any) => {
      setConfirmLoading(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        removeWindowClass('login-page');
        removeWindowClass('hold-transition');
        dispatch(confirmRegistration());
        dispatch(loginUser({'token': response.uuid}));
        toast.success(response.message);
        navigate(`/`);
      } else if (response.status === 2) {
          toast.error(response.message);
      }
    }, values, (error: any) => {
      if (error.response.status === 422) {
          actions.setErrors(error.response.data.errors);
      }
      setConfirmLoading(false);
      actions.setSubmitting(false);
    });
  };

  const resendConfirmation = async () => {
    setResendConfirmLoading(true);
    await apiRequest(`user/resend_confirmation`, (response: any) => {
      setResendConfirmLoading(false);
      if (response.status === 0) {
            toast.success(response.message);
        } else if (response.status === 2) {
            toast.error(response.message);
        }
    }, {"useruuid": useruuid}, () => {
      setResendConfirmLoading(false);
    });
  };

   const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      confirmationCode: '',
      useruuid
    },
    validationSchema: Yup.object({
      confirmationCode: Yup.number()
            .required(t('validation.required')),
    }),
    onSubmit: submitConfirmRegistration,
  });

  setWindowClass('hold-transition register-page');

  return (
    <>
      <Header/>
      <div className="register-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <Title/>
          </div>
          <div className="card-body">
            <div className="login-box-msg">{t('confirmAccount.title')}</div>
            <div className="login-box-msg">{t('confirmAccount.description')}</div>
            <div className="alert alert-warning alert-dismissible">
              <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
              <h6><i className="icon fas fa-exclamation-triangle" /> {t('confirmAccount.noconfirmed')}</h6>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <InputGroup className="mb-3">
                  <Form.Control
                    id="confirmationCode"
                    name="confirmationCode"
                    type="text"
                    placeholder={t('confirmAccount.code')}
                    onChange={handleChange}
                    value={values.confirmationCode}
                    isValid={touched.confirmationCode && !errors.confirmationCode}
                    isInvalid={touched.confirmationCode && !!errors.confirmationCode}
                  />
                  {touched.confirmationCode && errors.confirmationCode ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmationCode}
                    </Form.Control.Feedback>
                  ) : (
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faBullseye}/>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  )}
                </InputGroup>
              </div>
              <div className="mb-3">
                <Button
                  type="submit"
                  block
                  theme="outline-primary"
                  isLoading={isConfirmLoading}
                  disabled={isResendConfirmLoading}
                >
                  {t('confirmAccount.confirm')}
                </Button>
              </div>
              <div className="mb-3">
                <Button
                  type="button"
                  onClick={() => resendConfirmation()}
                  block
                  theme="outline-info"
                  isLoading={isResendConfirmLoading}
                  disabled={
                    isConfirmLoading
                  }
                >
                  {t('confirmAccount.resend')}
                </Button>
              </div>
            </form>
            <p className="mt-3 mb-1">
              <Link to="/login">{t('login.button.signIn.label')}</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmRegistration;
