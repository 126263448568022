import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';

const ConfirmRegistrationRoute = () => {
  const confirmRegistration = useSelector((state: any) => state.auth.confirmRegistration);

  return !confirmRegistration ? <Navigate to="/" /> : <Outlet />;
};

export default ConfirmRegistrationRoute;
