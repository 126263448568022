import {createSlice} from '@reduxjs/toolkit';

export interface AuthState {
  isLoggedIn: boolean;
  confirmRegistration: boolean;
  completeRegistration: boolean;
  loading: boolean;
  loadingError: boolean;
  token: string | null;
  currentUser: any;
  logo: string;
  data: object;
}

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem('token'),
  confirmRegistration: false,
  completeRegistration: false,
  loading: false,
  loadingError: false,
  token: localStorage.getItem('token'),
  currentUser: {
    email: 'mail@example.com',
    is_admin: false,
    picture: null,
    username: null
  },
  logo: '',
  data: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, {payload}) => {
      localStorage.setItem('token', payload.token);
      state.isLoggedIn = true;
      state.token = payload.token;
    },
    logoutUser: (state) => {
      localStorage.removeItem('token');
      state.currentUser = {};
      state.isLoggedIn = false;
      state.token = null;
    },
    unConfirmRegistration: (state) => {
      state.confirmRegistration = true;
    },
    confirmRegistration: (state) => {
      state.confirmRegistration = false;
    },
    completeRegistration: (state) => {
      state.completeRegistration = true;
    },
    completedRegistration: (state) => {
      state.completeRegistration = false;
    },
    loadUser: (state, {payload}) => {
      state.currentUser = payload;
    },
    loadCSRF: (state, {payload}) => {
      state.logo = payload.logo
    },
    loadPageData: (state, {payload}) => {
      state.data = payload.length ? payload[0] : []
    },
    loadPageDataSingle: (state, {payload}) => {
      state.data = payload.length ? payload : []
    },
    showLoading: (state) => {
      state.loading = true;
      state.loadingError = false;
    },
    dismissLoading: (state) => {
      state.loading = false;
    },
    loadingError: (state) => {
      state.loading = false;
      state.loadingError = true;
    },
  }
});

export const {loginUser, logoutUser, unConfirmRegistration, confirmRegistration, completedRegistration, completeRegistration, loadUser, loadCSRF, loadPageData, loadPageDataSingle, showLoading, dismissLoading, loadingError} = authSlice.actions;

export default authSlice.reducer;
