import React, {ChangeEventHandler} from "react";
import {Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

export interface InputTextProps {
  title?: string;
  name: string;
  handleChange: ChangeEventHandler;
  value?: any;
  touched?: boolean;
  error?: string,
  icon: IconDefinition;
  disabled?: boolean;
}

const InputText = ({title="", name, handleChange, value="", touched=false, error="", disabled=false, icon}: InputTextProps) => {
  return (
    <InputGroup className="mb-3">
      <Form.Control
        placeholder={title}
        id={name}
        name={name}
        type="text"
        onChange={handleChange}
        value={value}
        isValid={touched && !error}
        isInvalid={touched && !!error}
        disabled={disabled}
      />
      {touched && error ? (
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      ) : (
        <InputGroup.Append>
          <InputGroup.Text>
            <FontAwesomeIcon icon={icon}/>
          </InputGroup.Text>
        </InputGroup.Append>
      )}
    </InputGroup>
  );
};

export default InputText;
