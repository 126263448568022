import React, {ChangeEventHandler, FormEventHandler, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {loadPageDataSingle} from "@store/reducers/auth";
import apiRequest from "@app/utils/axios";
import {toast} from "react-toastify";
import {Formik, Form, Field, useFormik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {Button, InputText} from "@components";
import {confirmAlert} from "react-confirm-alert";
/* import 'react-confirm-alert/src/react-confirm-alert.css'; */

const ManageAttribute = () => {
  const [isAddLoading, setAddLoading] = useState(false);
  const [editAttribute, setEditAttribute] = useState({id: null, value: "", type: ""});
  const attributes: any = useSelector((state: any) => state.auth.data);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const dataTypeOptions: any = [
    {value: 'text', label: 'Text'},
    {value: 'date', label: 'Date'}
  ];
  const addAttribute = (data: object) => {
    const newAttributes: any = [...attributes, data]; /* type: "text", name: "Wendy", id: 5912 */
    dispatch(loadPageDataSingle(newAttributes));
  };
  const removeAttribute = (id: any) => {
    const newAttributes = [...attributes].filter((attribute) => attribute.id !== id);
    dispatch(loadPageDataSingle(newAttributes));
  };
  const deregisterAttribute = async (id: any) => {
    apiRequest(`contact/removeattribute`, (response: any) => {
      if (response.status === 0) {
        toast.success(response.message);
          removeAttribute(id);
      } else if (response.status === 3) {
        toast.error(response.message);
      }
    }, {id});
  };
  const registerAttribute = async (values: object, actions: any) => {
    setAddLoading(true);
    actions.setSubmitting(true);
    await apiRequest(`contact/addattribute`, (response: any) => {
      setAddLoading(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        toast.success(response.message);
        addAttribute(response.attribute);
        actions.resetForm();
      } else if (response.status === 3) {
        toast.error(response.message);
      }
    }, values, (error: any) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
      setAddLoading(false);
      actions.setSubmitting(false);
    });
  };
  const updateAttribute = async (values: any, actions: any) => {
    setAddLoading(true);
    actions.setSubmitting(true);
    await apiRequest(`contact/editattribute`, (response: any) => {
      setAddLoading(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        toast.success(response.message);
        setEditAttribute({id: null, value: "", type: ""});
        const newAttributes: any = [...attributes].map((item) => (item.id === values.id ? response.val : item));
        dispatch(loadPageDataSingle(newAttributes));
      } else if (response.status === 3) {
        toast.error(response.message);
      }
    }, values, () => {
      setAddLoading(false);
      actions.setSubmitting(false);
    });
  };
  const {handleChange, values, handleSubmit, touched, errors}: {handleChange: ChangeEventHandler, values: any, handleSubmit: FormEventHandler<any>, touched: any, errors: any} = useFormik({
    initialValues: {
      type: '',
      value: '',
    },
    validationSchema: Yup.object({
      type: Yup.string()
          .required(t('validation.required')),
      value: Yup.string()
          .required(t('validation.required')),
    }),
    onSubmit: registerAttribute,
  });
  useEffect( () => {
    apiRequest(`contact/loadattributes`, (response: any) => {
      dispatch(loadPageDataSingle(response));
      /* console.log(response[0]); */
    });
    return () => {
    };
  }, []);
  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{t('views.contact.aattribute')}</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus" />
                </button>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="remove"
                  data-toggle="tooltip"
                  title="Remove"
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="offset-3 col-6" style={{borderRight:'1px dashed #ccc'}}>
                  <form onSubmit={handleSubmit} className="form-inline mb-3">
                    <div className="form-group mx-sm-2 mb-3">
                      <Select
                        styles={{control: (styles) => ({...styles, width: 200})}}
                        name="type"
                        options={dataTypeOptions}
                        placeholder={t('views.contact.datatype')}
                        onChange={(selectedOptions: any) => {
                          const event: any = { target : { name:'type', value: selectedOptions.value}};
                          handleChange(event);
                        }}
                      />
                    </div>
                    <div className="form-group mx-sm-1">
                      <InputText
                        title={t('views.contact.dataname')}
                        name="value"
                        handleChange={handleChange}
                        value={values.value}
                        touched={touched.value}
                        error={errors.value}
                        icon={faBook}
                      />
                    </div>
                    <Button
                      type="submit"
                      theme="outline-primary"
                      isLoading={isAddLoading}
                      className="mb-3"
                    >
                      {t('views.crud.add')}
                    </Button>
                  </form>
                </div>
              </div>
              <hr/>
              <div className="row">
                <div className="offset-3 col-4">
                  <div className="text-center">
                    {editAttribute.id ? (
                      <>
                        <h3>{t('views.contact.uattribute')}</h3>
                        <Formik
                          initialValues={{...editAttribute}}
                          onSubmit={updateAttribute}
                        >
                        {() => (
                          <Form className="form-inline">
                            <div className="form-group mx-sm-2">
                              <Field name='id' type="hidden" />
                              <Field name="type" as="select" className="form-control" >
                                {dataTypeOptions.map((option: any) => (
                                  <option value={option.value} key={option.value}>{option.label}</option>
                                ))}
                              </Field>
                            </div>
                            <div className="form-group mx-sm-2">
                              <Field name="value" type="text" className="form-control" />
                            </div>
                            <Button type="submit" isLoading={isAddLoading} theme="outline-primary">{t('views.crud.update')}</Button>
                          </Form>
                        )}
                        </Formik>
                      </>
                    ) : (
                      <>
                        <h3>{t('views.contact.sattributes')}</h3>
                        {attributes.length ? (
                          <>
                            <ul className="list-group">
                              {attributes.map((attribute: any) => (
                                <li key={attribute.id} className="list-group-item d-flex justify-content-between align-items-center">
                                  <span>
                                    {attribute.value}
                                  </span>
                                  <span className="badge badge-secondary badge-pill">
                                    {attribute.type}
                                  </span>
                                  <span>
                                    <button type="button" aria-label="E" className="fas fa-edit" style={{color:'green'}} tabIndex={0} onClick={() => setEditAttribute({id: attribute.id, value: attribute.value, type: attribute.type})} />
                                    {' '}
                                    <button type="button" aria-label="D" className="fas fa-trash-alt" style={{color:'red'}} tabIndex={0} onClick={() => confirmAlert({title: '', message : t('views.crud.confirm'), buttons: [{label:t('views.crud.yes'), onClick: () => deregisterAttribute(attribute.id)}, {label: t('views.crud.no'), onClick: () => {return false;}}]})} />
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <div className="alert alert-light" role="alert">
                            {t('views.contact.nattributes')}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">&nbsp;</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManageAttribute;
