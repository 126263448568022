import React, {useEffect, useState} from 'react';
/* import {ContentHeader} from '@components'; */
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate, useLocation} from "react-router-dom";
import apiRequest from "@app/utils/axios";
import {toast} from "react-toastify";
/* import parse from "html-react-parser"; */
import {loadPageData} from "@store/reducers/auth";
/* import GoogleLogin from "@caranmegil/react-google-login"; */
import { useGoogleLogin } from '@react-oauth/google';
import parse from 'html-react-parser';
import {Button, DatatableRemote} from "@app/components";
/* import {httpHostUrl} from '@app/utils/settings'; */

const ContactIndex = () => {
  /* console.log(props);
  const {location} = props; */
  const { state }: {state: any} = useLocation();
  /* console.log(state); */
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {groupname, groupuuid} = state || [];
  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
  const serverData = useSelector((state: any) => state.auth.data);
  const columns = [
    {
      name: t('views.contact.first_name'),
      selector: (row: any) => row.first_name,
    },
    {
      name: t('views.contact.last_name'),
      selector: (row: any) => row.last_name,

    },
    {
      name: t('views.contact.phone_number'),
      selector: (row: any) => row.phone_number,
    },
    {
      name: t('views.contact.status'),
      cell: (cols: any) => <span className="badge badge-pill badge-info">{cols.issubscribed ? t('views.contact.subscribed') : t('views.contact.created')}</span>,
    },
    {
      name: t('views.button.action'),
      cell: (cols: any) => <Link to={`/contact/edit/${cols.uuid}`} className="btn btn-sm btn-send-sms"><i className="fa fa-pencil-alt"/> {t('views.crud.edit')}</Link>,
    },
  ];
  const responseGoogle: any = useGoogleLogin({
    onSuccess: async (response: any) => {
      /* console.log(response) */
      await apiRequest(`contact/loadgoogle`, (response: any) => {
        setGoogleAuthLoading(false);
        if (response.status === 0) {
          toast.success(response.message);
          navigate(`/group/view/${response.groupuuid}`);
        } else if (response.status === 3) {
          toast.error(response.message);
        }
      }, {accessToken: response.access_token}, () => {
        setGoogleAuthLoading(false);
      });
    },
    onError: () => console.log('Login Failed'),
    scope:"https://www.googleapis.com/auth/contacts.readonly",
    /* flow: "auth-code" */
  });
  /* const responseGoogle = async (response: any) => {
    console.log(response);
    await apiRequest(`contact/loadgoogle`, (response: any) => {
      setGoogleAuthLoading(false);
      if (response.status === 0) {
        toast.success(response.message);
        navigate(`/group/view/${response.groupuuid}`);
      } else if (response.status === 3) {
        toast.error(response.message);
      }
    }, {token: response.tokenId, accessToken: response.accessToken, credential: response.credential}, () => {
      setGoogleAuthLoading(false);
    });
  }; */
  useEffect( () => {
    apiRequest(`contact/summary`, (response: any) => {
      dispatch(loadPageData(response));
    });
    return () => {
    };
  }, []);

  return (
    <div>
      {/* <ContentHeader title={`${groupname ? parse(`${t('views.contact.glist', {'group': groupname})}`) : t('views.contact.list')}`} /> */}
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{groupname ? parse(`${t('views.contact.glist', {'group': groupname})}`) : t('views.contact.list')}</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus" />
                </button>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="remove"
                  data-toggle="tooltip"
                  title="Remove"
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-3">
                  <span className="btn btn-app bg-secondary">
                    <span className="badge bg-info text-md">{serverData.subscribed}</span>
                    <i className="fas fa-plug" /> <span className="text-sm">{t('views.contact.nsubscribed')}</span>
                  </span>
                  &nbsp;&nbsp;
                  <span className="btn btn-app bg-secondary">
                    <span className="badge bg-info text-md">{serverData.stored}</span>
                    <i className="fas fa-user-plus" /> <span className="text-sm">{t('views.contact.ncreated')}</span>
                  </span>
                </div>
                <div className="offset-3 col-3">
                  <Link to="/contact/add" className="btn btn-block btn-send-sms"><i className="far fa-address-card"/> {t('views.contact.add')}</Link>
                  <Link to="/contact/import" className="btn btn-block btn-send-sms"><i className="far fa-file-excel"/> {t('views.contact.fimport')}</Link>
                </div>
                <div className="col-3">
                  {/* <GoogleLogin
                    clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                    buttonText={t('views.contact.googleimport')}
                    theme='dark'
                    disabled={isGoogleAuthLoading}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy='single_host_origin'
                    scope="https://www.googleapis.com/auth/contacts.readonly"
                    accessType='offline'
                    prompt='consent'
                    responseType='permission'
                  /> */}
                  <Button
                    block
                    icon="google"
                    theme="outline-danger"
                    onClick={() => responseGoogle()}
                    isLoading={isGoogleAuthLoading}
                  >
                    {t('views.contact.googleimport')}
                  </Button>
                  {/* <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
                     <GoogleLogin
                      onSuccess={responseGoogle}
                      onError={() => {
                        console.log('Login Failed');
                      }}
                      useOneTap
                    />
                  </GoogleOAuthProvider> */}
                </div>
              </div>
              <hr/>
              <DatatableRemote
                title="Contacts"
                columns={columns}
                params={{"datatable": "contact/list", "delete":"contact/post_delete", groupuuid}}
                buttonSelector="contacts"
              />


            </div>
            <div className="card-footer">&nbsp;</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactIndex;
