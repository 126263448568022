import React, {ChangeEventHandler, FormEventHandler, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import apiRequest from "@app/utils/axios";
import {toast} from "react-toastify";
import {loadPageData} from "@store/reducers/auth";
import {useFormik} from "formik";
import Select from "react-select";
import {Form} from "react-bootstrap";
import {Button, InputText} from "@components";
import {faUserGroup} from "@fortawesome/free-solid-svg-icons";

const AddToGroup = () => {
  const { state }: {state: any} = useLocation();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const serverData = useSelector((state: any) => state.auth.data);
  const {contacts, groupuuid} = state || [];
  const [isAddLoading, setAddLoading] = useState(false);

  const postAddToGroup = async (values: object, actions: any) => {
    setAddLoading(true);
    actions.setSubmitting(true);
    await apiRequest(`contact/addtogroup`, (response: any) => {
      setAddLoading(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        toast.success(response.message);
        if (groupuuid) {
          navigate(`/group/view/${groupuuid}`);
        } else {
          navigate('/contact');
        }
      } else if (response.status === 3) {
        toast.error(response.message);
      }
    }, {...values, ...contacts, groupuuid}, (error: any) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
      setAddLoading(false);
      actions.setSubmitting(false);
    });
  };
  const {handleChange, handleSubmit, touched, errors}: {handleChange: ChangeEventHandler, values: any, handleSubmit: FormEventHandler<any>, touched: any, errors: any} = useFormik({
    initialValues: serverData.form_initials,
    enableReinitialize: true,
    onSubmit: postAddToGroup,
  });
  useEffect( () => {
    apiRequest(`contact/addtogroup_summary`, (response: any) => {
      dispatch(loadPageData(response));
      /* console.log(response); */
    }, {...contacts, groupuuid});
    return () => {
    };
  }, []);

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{t('views.contact.gadd')}</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus" />
                </button>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="remove"
                  data-toggle="tooltip"
                  title="Remove"
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="offset-3 col-4">
                  <div className="text-center">
                    <table className="table table-hover table-striped">
                      <thead>
                      <tr>
                        <th scope="col">{t('views.contact.first_name')}</th>
                        <th scope="col">{t('views.contact.last_name')}</th>
                        <th scope="col">{t('views.contact.phone_number')}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {serverData.contacts && serverData.contacts.map((contact: any) => (
                        <tr key={contact.id}>
                          <th scope="row">{contact.first_name}</th>
                          <td>{contact.last_name}</td>
                          <td>{contact.phone_number}</td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <hr/>
              <div className="row">
                <div className="offset-3 col-4">
                  <form onSubmit={handleSubmit}>
                    {groupuuid ? (
                      <>
                        <label htmlFor="group">{t('views.contact.group')}</label>
                        <InputText
                          name="group"
                          handleChange={handleChange}
                          value={serverData.group_name}
                          icon={faUserGroup}
                          disabled
                        />
                      </>
                    ) : (
                      <div className="mb-3">
                        <Select
                          name="group"
                          options={serverData.groups}
                          /* defaultValue={serverData.selected_group} */
                          placeholder={t('views.contact.group')}
                          isMulti
                          onChange={(selectedOptions) => {
                            const event: any = { target : { name:'group', value: selectedOptions.map((option: any) => {return option.value})}};
                            handleChange(event);
                          }}
                          /* onInputChange={handleChange} */
                        />
                        {(touched.group && errors.group) && (
                          <Form.Control.Feedback type="invalid">
                            {errors.group}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    )}
                    <div className="text-center mt-2">
                      <Link to={{pathname: groupuuid ? `/group/view/${groupuuid}` : "/contact"}} className="btn btn-outline-danger">
                        <span>{t('views.label.cancel')}</span>
                      </Link>
                      {' '}
                      <Button
                        type="submit"
                        theme="outline-primary"
                        isLoading={isAddLoading}
                      >
                        {t('views.crud.add')}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card-footer">&nbsp;</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddToGroup;
