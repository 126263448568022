import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Dropdown} from '@components';
import {useSelector} from "react-redux";

const MessagesDropdown = () => {
  const [t] = useTranslation();
  const user = useSelector((state: any) => state.auth.currentUser);

  return (
    <Dropdown
      isOpen={false}
      size="lg"
      buttonTemplate={
        <>
          <i className="far fa-folder-open fa-fw fa-2x" />
          <span className="badge badge-info navbar-badge even-larger-badge">{user.pendings && user.pendings.length}</span>
        </>
      }
      menuTemplate={
        <>
          {user.pendings && user.pendings.map((option: any) => (
            <div  key={option.uuid}>
              <Link to="/" className="dropdown-item">
                <div className="media">
                  <img
                    src="/img/default-profile.png"
                    alt="User Avatar"
                    className="img-size-50 mr-3 img-circle"
                  />
                  <div className="media-body">
                    <h3 className="dropdown-item-title">
                      {option.title}
                      <span className="float-right text-sm text-danger">
                      <i className="fas fa-star" />
                    </span>
                    </h3>
                    <p className="text-sm">{option.message}</p>
                    <p className="text-sm text-muted">
                      <i className="far fa-clock mr-1" />
                      <span>
                      {option.time}
                        {/* {t('header.messages.ago', {
                        quantity: '30',
                        unit: 'Minutes'
                      })} */}
                    </span>
                    </p>
                  </div>
                </div>
              </Link>
              <div className="dropdown-divider" />
            </div>
          ))}
          <Link to="/" className="dropdown-item dropdown-footer">
            {t('header.messages.seeAll')}
          </Link>
        </>
      }
    />
  );
};

export default MessagesDropdown;
