import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';

const AdminRoute = () => {
  const isAdmin = useSelector((state: any) => state.auth.currentUser.is_admin);

  return !isAdmin ? <Navigate to="/" /> : <Outlet />;
};

export default AdminRoute;
