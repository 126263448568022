import React from 'react';
import {ResponsiveContainer, LineChart, Line, XAxis, Tooltip, CartesianGrid, YAxis} from "recharts";

export interface LineChartProps {
  data: any;
  xdatakey: string;
  linekey: string;
}

const AppLineChart = ({data, xdatakey, linekey}: LineChartProps) => {
  return (
    <ResponsiveContainer width="90%" height={400}>
      <LineChart
        data={data}
        margin={{top: 5, right: 20, left: 10, bottom: 5}}
      >
        <YAxis type='number' orientation='left' yAxisId={0}/>
        <XAxis dataKey={xdatakey}/>
        <Tooltip/>
        <CartesianGrid stroke="#f5f5f5" fill="#e6e6e6" />
        <Line type="monotone" dataKey={linekey} stroke="#ff7300" yAxisId={0}/>
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AppLineChart;
