import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {DateTime} from 'luxon';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import {version} from '../../../../package.json';

const Footer = () => {
  const [t] = useTranslation();
  const user = useSelector((state: any) => state.auth.currentUser);

  return (
    <footer className="main-footer">
      {user.show_logo ? <img
        src={user.logo}
        alt="Brand Logo"
        style={{opacity: '.8', width: '5%', height: '2%'}}
      /> : ""}
      <strong>
        <span> </span>
        <span>{user.business_name}</span>
        <span> </span>
        <span>Copyright © {DateTime.now().toFormat('y')} </span>
        <span>.</span>
      </strong>
      <span> </span>
      <span>{t('footer.copyright')}</span>
      <div className="float-right d-none d-sm-inline-block">
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={(
              <Tooltip id="button-tooltip">
                <ul className="list-inline">
                  {user.notifications && user.notifications.filter((attribute: any) => attribute.category === 2).map((option: any) => (
                      <li className="list-inline-item">{option.data.title}</li>
                  ))}
                </ul>
              </Tooltip>
          )}
        >
          {({ ref, ...triggerHandler }) => (
            <Button
              variant="light"
              {...triggerHandler}
              className="d-inline-flex align-items-center"
            >
              <i className="fas fa-tasks fa-fw" />
              {' '}
              <span ref={ref} className="ms-1">{t('views.tasks.background', {'count': user.notifications && user.notifications.filter((attribute: any) => attribute.category === 2).length})}</span>
            </Button>
          )}
        </OverlayTrigger>
        <span>&nbsp;</span>
        <b>{t('footer.version')}</b>
        <span>&nbsp;{version}</span>
      </div>
    </footer>
  );
};

export default Footer;
