import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import DataTable from 'react-data-table-component'
import { confirmAlert } from 'react-confirm-alert';
import {toast} from 'react-toastify';
import {Button} from "@components";
import apiRequest from "@app/utils/axios";

export interface FilterProps {
  filterTextV: string;
  onFilter: ChangeEventHandler;
  onClear: MouseEventHandler;
  onSearch: MouseEventHandler;
  filterTitle: string;
}
const FilterComponent = ({ filterTextV, onFilter, onClear, onSearch, filterTitle }: FilterProps) => (
    <>
        <input
            id="search"
            type="text"
            value={filterTextV}
            onChange={onFilter}
        />
        <input type="button" value={filterTitle} onClick={onSearch} />
        {/* TODO: implement search from the backend */}
        <input type="button" value="X" onClick={onClear} />
    </>
);
const Export = ({ onExport, exportTitle }: {onExport: Function, exportTitle: string}) => <Button onClick={(e: any) => onExport(e.target.value)}>{exportTitle}</Button>;
const DatatableRemote = (props: any) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const {title, columns, params, updateDt, buttonSelector} = props;

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);
  const handlePageChange = (tpage: number) => {
    setPage(tpage);
  };
  let isMounted = true;
  const handlePerRowsChange = async (newPerPage: number) => {
    setPerPage(newPerPage);
  };
  const downloadCSV = () => {
    const link = document.createElement('a');
    let csv = "";
    if (csv === "") return;
    const filename = 'export.csv';
    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  };
  const fetchServerData = async () => {
    setLoading(true);
    await apiRequest(params.datatable, (response: any) => {
      setData(response.data);
      setTotalRows(response.total);
      setLoading(false);
    }, {page, perPage, filterText});
  };
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    const handleSearch = () => {
      fetchServerData();
    };
    return (
      <>
        <FilterComponent onFilter={(e: any) => setFilterText(e.target.value)} onSearch={handleSearch} onClear={handleClear} filterTextV={filterText} filterTitle={t('views.label.search')} />
      </>
    );
  }, [filterText, resetPaginationToggle]);
  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({title: '', message : t('views.crud.confirm'), buttons: [{label:t('views.crud.yes'), onClick: async () => {
        await apiRequest(params.delete, (response: any) => {
          if (response.status === 0) {
            toast.success(response.message);
            setToggleCleared(!toggleCleared);
            fetchServerData();
          } else if (response.status === 3) {
            toast.error(response.message);
          }
        }, {...selectedRows.map((r: any) => r.uuid)});
      }}, {label: t('views.crud.no'), onClick: () => {return false;}}]});
    };
    let template;
    if (buttonSelector === "contact_groups") {
      template = (
        <Button key="group_delete" onClick={handleDelete} style={{ backgroundColor: 'red' }} icon="">
          {t('views.crud.delete')}
        </Button>
      );
    } else if (buttonSelector === "contacts") {
      template = (
        <>
          <Link
            to={{pathname:"/contact/togroup",}}
            state={{contacts: {...selectedRows.map((r: any) => r.uuid)}, groupuuid: params.groupuuid}}
            className="btn btn-secondary">{t('views.contact.addgroup')}
          </Link>
          <Button key="contact_delete" onClick={handleDelete} style={{ backgroundColor: 'red' }} icon="">
            {t('views.crud.delete')}
          </Button>
        </>
      );
    } else if (buttonSelector === "contact_group_contacts") {
      template = (
        <>
          <Button key="group_contact_delete" onClick={handleDelete} style={{ backgroundColor: 'red' }} icon="">
            {t('views.group.remove')}
          </Button>
        </>
      );
    }
    return template;
  }, [data, selectedRows, toggleCleared]);
  const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV()} exportTitle={t('views.label.export')} />, []);
  useEffect(() => {
      if (isMounted) {
          fetchServerData();
      }
      return () => {
          isMounted = false;
      };
  }, [page, perPage, updateDt]);

  return (
      <DataTable
        title={title}
        columns={columns}
        data={data}
        progressPending={loading}
        actions={actionsMemo}
        pagination
        paginationServer
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        /* subHeaderAlign="left" */
        subHeaderWrap
        subHeaderComponent={subHeaderComponentMemo}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        dense
        /* direction="auto" */
        fixedHeader
        responsive
        selectableRows
        contextActions={contextActions}
        selectableRowsHighlight
        persistTableHead
      />
  );

};
export default DatatableRemote;
