import React, {ChangeEventHandler, FormEventHandler, useEffect, useState} from 'react';
import {Button, InputRadio, InputText} from '@components';
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import apiRequest from "@app/utils/axios";
import {toast} from "react-toastify";
import {loadPageData} from "@store/reducers/auth";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Form} from "react-bootstrap";
import {faAddressBook, faMobileAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import CountrySelect from "@app/components/select/CountrySelect";
import Select from "react-select";

const EditContact = () => {
  const { state }: {state: any} = useLocation();
  const {groupuuid} = state || [];
  const serverData: any = useSelector((state: any) => state.auth.data);
  const [isAddLoading, setAddLoading] = useState(false);
  const [appLoadingState, updateAppLoading] = useState(true);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { editId } = useParams();

  const postEditContact = async (values: object, actions: any) => {
    setAddLoading(true);
    actions.setSubmitting(true);
    await apiRequest(`contact/post_edit`, (response: any) => {
      setAddLoading(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        toast.success(response.message);
        if (groupuuid) {
          navigate(`/group/view/${groupuuid}`);
        } else {
          navigate('/contact');
        }
      } else if (response.status === 3) {
        toast.error(response.message);
      }
    }, values, (error: any) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
      setAddLoading(false);
      actions.setSubmitting(false);
    });
  };
  const {handleChange, values, handleSubmit, touched, errors}: {handleChange: ChangeEventHandler, values: any, handleSubmit: FormEventHandler<any>, touched: any, errors: any} = useFormik({
    initialValues: serverData.saved_contact,
    enableReinitialize: true,
    validationSchema: Yup.object({
      first_name: Yup.string()
        .required(t('validation.required')),
      last_name: Yup.string()
        .required(t('validation.required')),
      phone_number: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .required(t('validation.required')),
      sex: Yup.string()
        .required(t('validation.required')),
    }),
    onSubmit: postEditContact,
  });
  useEffect( () => {
    apiRequest(`contact/get_edit`, (response: any) => {
      dispatch(loadPageData(response));
      updateAppLoading(false);
      /* console.log(response[0].saved_groups); */
    }, {editId});
    return () => {
    };
  }, []);

  let template;
  if (appLoadingState) {
    template = <></>
  } else {
    template =  (
      <div>
        {/* <ContentHeader title="Blank Page" /> */}
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">{t('views.label.update')}</h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                  >
                    <i className="fa fa-minus" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-widget="remove"
                    data-toggle="tooltip"
                    title="Remove"
                  >
                    <i className="fa fa-times" />
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="offset-2 col-4">
                      <div className="mb-3">
                        <InputText
                          title={t('views.contact.first_name')}
                          name="first_name"
                          handleChange={handleChange}
                          value={values.first_name}
                          touched={touched.first_name}
                          error={errors.first_name}
                          icon={faUser}
                        />
                      </div>
                      <div className="row">
                        <div className="col-8">
                          <InputText
                            title={t('views.contact.phone_number')}
                            name="phone_number"
                            handleChange={handleChange}
                            value={values.phone_number}
                            touched={touched.phone_number}
                            error={errors.phone_number}
                            icon={faMobileAlt}
                          />
                        </div>
                        <div className="col-4">
                          <CountrySelect
                            value={serverData.saved_contact && serverData.saved_contact.code}
                            onChange={(selectedOption: any) => {
                              const event: any = { target : { name:"code", value: selectedOption.value}};
                              handleChange(event);
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Select
                          name="group"
                          options={serverData.groups}
                          defaultValue={serverData.saved_groups}
                          placeholder={t('views.contact.group')}
                          isMulti
                          onChange={(selectedOptions: any) => {
                            const event: any = { target : { name:'group', value: selectedOptions.map((option: any) => {return option.value})}};
                            handleChange(event);
                          }}
                        />
                        {(touched.group && errors.group) && (
                          <Form.Control.Feedback type="invalid">
                            {errors.group}
                          </Form.Control.Feedback>
                        )}
                      </div>

                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                        <InputText
                          title={t('views.contact.last_name')}
                          name="last_name"
                          handleChange={handleChange}
                          value={values.last_name}
                          touched={touched.last_name}
                          error={errors.last_name}
                          icon={faUser}
                        />
                      </div>
                      <InputRadio
                        title={t('views.contact.gender')}
                        name="sex"
                        value={serverData.saved_contact && serverData.saved_contact.sex}
                        radios={[
                          {name: t('views.contact.male'), value: "7"},
                          {name: t('views.contact.female'), value: "8"}
                        ]}
                        handleChange={handleChange}
                        touched={touched.sex}
                        error={errors.sex}
                      />
                    </div>
                  </div>
                  <p className="">{t('views.contact.sattributes')}</p>
                  <div className="row">
                    <div className="offset-2 col-4">
                      {serverData.attributes && serverData.attributes.slice(0, Math.ceil(serverData.attributes.length/2)).map((attribute: any) => (
                        <div className="mb-3" key={attribute.id}>
                          <InputText
                            title={attribute.value}
                            name={attribute.id}
                            handleChange={handleChange}
                            value={values[attribute.id]}
                            touched={touched[attribute.id]}
                            error={errors[attribute.id]}
                            icon={faAddressBook}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="col-4">
                      {serverData.attributes && serverData.attributes.slice(Math.ceil(serverData.attributes.length/2), serverData.attributes.length).map((attribute: any) => (
                        <div className="mb-3" key={attribute.id}>
                          <InputText
                            title={attribute.value}
                            name={attribute.id}
                            handleChange={handleChange}
                            value={values[attribute.id]}
                            touched={touched[attribute.id]}
                            error={errors[attribute.id]}
                            icon={faAddressBook}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="text-center">
                    <Link to={{pathname: groupuuid ? `/group/view/${groupuuid}` : "/contact"}} className="btn btn-outline-danger">
                      <span>{t('views.label.cancel')}</span>
                    </Link>
                    {' '}
                    <Button
                      type="submit"
                      theme="outline-primary"
                      isLoading={isAddLoading}
                    >
                      {t('views.crud.update')}
                    </Button>
                  </div>

                </form>
              </div>
              <div className="card-footer">&nbsp;</div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  return template;

};

export default EditContact;
