import React, {useState, useEffect, useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loadUser} from '@store/reducers/auth';
import {toggleSidebarMenu} from '@app/store/reducers/ui';
import {addWindowClass, removeWindowClass} from '@app/utils/helpers';
import ControlSidebar from '@app/modules/main/control-sidebar/ControlSidebar';
import Header from '@app/modules/main/header/Header';
import MenuSidebar from '@app/modules/main/menu-sidebar/MenuSidebar';
import Footer from '@app/modules/main/footer/Footer';
import apiRequest from "@app/utils/axios";
/* import {toast} from "react-toastify"; */
import PageLoading from "@app/components/page-loading/PageLoading";
import ApiLoading from "@app/components/page-loading/ApiLoading";
import {useTranslation} from "react-i18next";

const Main = () => {
  const dispatch = useDispatch();
  const menuSidebarCollapsed = useSelector(
    (state: any) => state.ui.menuSidebarCollapsed
  );
  const controlSidebarCollapsed = useSelector(
    (state: any) => state.ui.controlSidebarCollapsed
  );
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const user = useSelector((state: any) => state.auth.currentUser);
  const [t] = useTranslation();

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  const fetchProfile = async () => {
    await apiRequest(`user/whoami`, (response: any) => {
      dispatch(loadUser(response));
      setIsAppLoaded(true);
    });
  };

  useEffect(() => {
    removeWindowClass('register-page');
    removeWindowClass('login-page');
    removeWindowClass('hold-transition');

    addWindowClass('sidebar-mini');

    fetchProfile();
    return () => {
      removeWindowClass('sidebar-mini');
    };
  }, []);

  useEffect(() => {
    removeWindowClass('sidebar-closed');
    removeWindowClass('sidebar-collapse');
    removeWindowClass('sidebar-open');
    if (menuSidebarCollapsed && screenSize === 'lg') {
      addWindowClass('sidebar-collapse');
    } else if (menuSidebarCollapsed && screenSize === 'xs') {
      addWindowClass('sidebar-open');
    } else if (!menuSidebarCollapsed && screenSize !== 'lg') {
      addWindowClass('sidebar-closed');
      addWindowClass('sidebar-collapse');
    }
  }, [screenSize, menuSidebarCollapsed]);

  useEffect(() => {
    if (controlSidebarCollapsed) {
      removeWindowClass('control-sidebar-slide-open');
    } else {
      addWindowClass('control-sidebar-slide-open');
    }
  }, [screenSize, controlSidebarCollapsed]);

  const getAppTemplate = useCallback(() => {
    if (!isAppLoaded) {
      return (
        <PageLoading />
      );
    }
    return (
      <>
        <Header />

        <MenuSidebar />

        <div className="content-wrapper">
          <div className="pt-3" />
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-5">
                  <span className="btn btn-app">
                    <span className="badge bg-info text-md">{user.contacts || 0}</span>
                    <i className="fas fa-address-book" /> <span className="text-sm">{t('header.label.contacts')}</span>
                  </span>
                  &nbsp;&nbsp;
                  <span className="btn btn-app bg-secondary">
                    <span className="badge bg-info text-md">{user.internet_sms || 0}</span>
                    <i className="fas fa-angle-double-down" /> <span className="text-sm">{t('views.sms.internet')}</span>
                  </span>
                  &nbsp;&nbsp;
                  <span className="btn btn-app">
                    <span className="badge bg-info text-md">{user.mobile_sms || 0}</span>
                    <i className="fas fa-mobile-alt" /> <span className="text-sm">{t('views.sms.mobile')}</span>
                  </span>
                  &nbsp;&nbsp;
                  <span className="btn btn-app">
                    <span className="badge bg-info text-md">{user.groups || 0}</span>
                    <i className="fas fa-users" /> <span className="text-sm">{t('views.label.groups')}</span>
                  </span>
                </div>
                <div className="col-2">
                  <ApiLoading />
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <Outlet />
          </section>
        </div>
        <Footer />
        <ControlSidebar />
        <div
          id="sidebar-overlay"
          role="presentation"
          onClick={handleToggleMenuSidebar}
          onKeyDown={() => {}}
        />
      </>
    );
  }, [isAppLoaded]);

  return <div className="wrapper">{getAppTemplate()}</div>;
};

export default Main;
