import {Link} from "react-router-dom";
import React from "react";

const Title = () => {
    return (
        <Link to="/" className="h1">
            <b>Messaging</b>
            <span>Service</span>
        </Link>
    );
};
export default Title;
