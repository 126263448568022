import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {Button} from '@components';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {setWindowClass} from '@app/utils/helpers';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Form, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {httpHref, httpPathname} from '@app/utils/settings';
import apiRequest from "@app/utils/axios";
import Title from "@modules/guest/Title";

const ForgotPassword = () => {
  const [isResetLoading, setResetLoading] = useState(false);
  const [isResetLocked, setResetLocked] = useState(false);
  const [t] = useTranslation();

  const requestNewPassword = async (values: object, actions: any) => {
    setResetLoading(true);
    actions.setSubmitting(true);
    await apiRequest(`user/forgot_password`, (response: any) => {
      setResetLoading(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        setResetLocked(true);
        toast.success(response.message);
      } else if (response.status === 2) {
          toast.error(response.message);
      }
    }, values, (error: any) => {
      if (error.response.status === 422) {
          actions.setErrors(error.response.data.errors);
      }
      setResetLoading(false);
      actions.setSubmitting(false);
    });
  };

  const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      email: '',
      http_origin: httpHref,
      http_pathname: httpPathname
    },
    validationSchema: Yup.object({
      email: Yup.string()
            .email(t('validation.email'))
            .required(t('validation.required')),
    }),
    onSubmit: requestNewPassword,
  });

  setWindowClass('hold-transition login-page');

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Title />
        </div>
        <div className="card-body">
          <p className="login-box-msg">{t('recover.forgotYourPassword')}</p>
          <form onSubmit={handleSubmit}>
            <Form.Control name='http_origin' type="hidden" />
            <Form.Control name='http_pathname' type="hidden" />
            <div className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  placeholder={t('forgotPassword.email')}
                  onChange={handleChange}
                  value={values.email}
                  isValid={touched.email && !errors.email}
                  isInvalid={touched.email && !!errors.email}
                />
                {touched.email && errors.email ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                ) : (
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                  </InputGroup.Append>
                )}
              </InputGroup>
            </div>
            <div className="row">
              <div className="col-12">
                <Button
                  type="submit"
                  block
                  theme="outline-primary"
                  isLoading={isResetLoading}
                  disabled={
                    isResetLocked
                  }
                >
                  {t('recover.requestNewPassword')}
                </Button>
              </div>
            </div>
          </form>
          <p className="mt-3 mb-1">
            <Link to="/login">{t('login.button.signIn.label')}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
