import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';

const CompleteRegistrationRoute = () => {
  const completeRegistration = useSelector((state: any) => state.auth.completeRegistration);

  return !completeRegistration ? <Navigate to="/" /> : <Outlet />;
};

export default CompleteRegistrationRoute;
