import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import 'css-loading'

const ApiLoading = () => {
    const [t] = useTranslation();
    const loading = useSelector((state: any) => state.auth.loading);
    const loadingError = useSelector((state: any) => state.auth.loadingError);
    if (loadingError) {
        return (
          <>
              <div className="alert alert-warning alert-dismissible">
                  <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                  <h6><i className="icon fas fa-exclamation-triangle" /> {t('views.label.loading_error')}</h6>
              </div>
          </>
        );
    }
    if (loading) {
        return (
          <>
              <div data-loader="wave" /> {t('views.label.loading')}
          </>
        )
    }
    return null;
};

export default ApiLoading;
