import React, {ChangeEventHandler, FormEventHandler, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import apiRequest from "@app/utils/axios";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import * as Yup from "yup";
import {loadPageData} from "@store/reducers/auth";
import {useDispatch, useSelector} from "react-redux";
import {Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faMobileAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import CountrySelect from "@app/components/select/CountrySelect";
import Select from "react-select";
import {Button} from "@components";
/* import {ContentHeader} from '@components'; */

const ContactCreate = () => {
  const { state }: {state: any} = useLocation();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {groupuuid} = state || [];
  const [isAddLoading, setAddLoading] = useState(false);
  const [appLoadingState, updateAppLoading] = useState(true);
  const serverData = useSelector((state: any) => state.auth.data);

  const postCreateContact = async (values: object, actions: any) => {
    setAddLoading(true);
    actions.setSubmitting(true);
    await apiRequest(`contact/post_create`, (response: any) => {
      setAddLoading(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        toast.success(response.message);
        if (groupuuid) {
          navigate(`/group/view/${groupuuid}`);
        } else {
          navigate('/contact');
        }
      } else if (response.status === 3) {
        toast.error(response.message);
      }
    }, values, (error: any) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
      setAddLoading(false);
      actions.setSubmitting(false);
    });
  };

  const {handleChange, values, handleSubmit, touched, errors}: {handleChange: ChangeEventHandler, values: any, handleSubmit: FormEventHandler<any>, touched: any, errors: any} = useFormik({
    initialValues: {
      'first_name': '',
      'phone_number': '',
      'last_name': '',
      'gender': null,
      ...serverData.contact_initials,
      ...serverData.attribute_values
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      first_name: Yup.string()
        .required(t('validation.required')),
      last_name: Yup.string()
        .required(t('validation.required')),
      phone_number: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .required(t('validation.required')),
      gender: Yup.string()
        .required(t('validation.required')),
    }),
    onSubmit: postCreateContact,
  });
  useEffect( () => {
    apiRequest(`contact/get_create`, (response: any) => {
      dispatch(loadPageData(response));
      /* console.log(response[0].contact_initials); */
      /* console.log(response[0].attribute_values); */
      updateAppLoading(false);
    }, {groupuuid});
    return () => {
    };
  }, []);

  let template;
  if (appLoadingState) {
    template = <></>
  } else {
    template = (
      <div>
        {/* <ContentHeader title="Blank Page" /> */}
        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">{t('views.contact.add')}</h3>
                <div className="card-tools">
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                  >
                    <i className="fa fa-minus" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-tool"
                    data-widget="remove"
                    data-toggle="tooltip"
                    title="Remove"
                  >
                    <i className="fa fa-times" />
                  </button>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="offset-2 col-4">
                      <div className="mb-3">
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="first_name"
                            name="first_name"
                            type="text"
                            placeholder={t('views.contact.first_name')}
                            onChange={handleChange}
                            value={values.first_name}
                            isValid={touched.first_name && !errors.first_name}
                            isInvalid={touched.first_name && !!errors.first_name}
                          />
                          {touched.first_name && errors.first_name ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.first_name}
                            </Form.Control.Feedback>
                          ) : (
                            <InputGroup.Append>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faUser}/>
                              </InputGroup.Text>
                            </InputGroup.Append>
                          )}
                        </InputGroup>
                      </div>
                      <div className="row">
                        <div className="col-8">
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="phone_number"
                              name="phone_number"
                              type="text"
                              placeholder={t('views.contact.phone_number')}
                              onChange={handleChange}
                              value={values.phone_number}
                              isValid={touched.phone_number && !errors.phone_number}
                              isInvalid={touched.phone_number && !!errors.phone_number}
                            />
                            {touched.phone_number && errors.phone_number ? (
                              <Form.Control.Feedback type="invalid">
                                {errors.phone_number}
                              </Form.Control.Feedback>
                            ) : (
                              <InputGroup.Append>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faMobileAlt}/>
                                </InputGroup.Text>
                              </InputGroup.Append>
                            )}
                          </InputGroup>
                        </div>
                        <div className="col-4">
                          <CountrySelect
                            value="TZ"
                            onChange={(selectedOption: any) => {
                              const event: any = { target : { name:"code", value: selectedOption.value}};
                              handleChange(event);
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <Select
                          name="group"
                          options={serverData.groups}
                          defaultValue={serverData.selected_group}
                          placeholder={t('views.contact.group')}
                          isMulti
                          onChange={(selectedOptions) => {
                            const event: any = { target : { name:'group', value: selectedOptions.map(option => {return option.value})}};
                            handleChange(event);
                          }}
                          /* onInputChange={handleChange} */
                        />
                        {(touched.group && errors.group) && (
                          <Form.Control.Feedback type="invalid">
                            {errors.group}
                          </Form.Control.Feedback>
                        )}
                      </div>

                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="last_name"
                            name="last_name"
                            type="text"
                            placeholder={t('views.contact.last_name')}
                            onChange={handleChange}
                            value={values.last_name}
                            isValid={touched.last_name && !errors.last_name}
                            isInvalid={touched.last_name && !!errors.last_name}
                          />
                          {touched.last_name && errors.last_name ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.last_name}
                            </Form.Control.Feedback>
                          ) : (
                            <InputGroup.Append>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faUser}/>
                              </InputGroup.Text>
                            </InputGroup.Append>
                          )}
                        </InputGroup>
                      </div>
                      <div key="inline-radio" className="mb-3">
                        <div>{t('views.contact.gender')}</div>
                        <Form.Check
                          inline
                          label={t('views.contact.male')}
                          name="gender"
                          type="radio"
                          value="7"
                          onChange={handleChange}
                          id="inline-radio-1"
                        />
                        <Form.Check
                          inline
                          label={t('views.contact.female')}
                          name="gender"
                          type="radio"
                          value="8"
                          onChange={handleChange}
                          id="inline-radio-2"
                        />
                        {(touched.gender && errors.gender) && (
                          <Form.Control.Feedback type="invalid">
                            {errors.gender}
                          </Form.Control.Feedback>
                        )}
                      </div>

                    </div>
                  </div>
                  <p className="">{t('views.contact.sattributes')}</p>
                  <div className="row">
                    <div className="offset-2 col-4">
                      {serverData.attributes && serverData.attributes.slice(0, Math.ceil(serverData.attributes.length/2)).map((attribute: any) => (
                        <div className="mb-3" key={attribute.id}>
                          <InputGroup className="mb-3">
                            <Form.Control
                              id={attribute.id}
                              name={attribute.id}
                              type="text"
                              placeholder={attribute.value}
                              onChange={handleChange}
                              value={values[attribute.id]}
                              isValid={touched[attribute.id] && !errors[attribute.id]}
                              isInvalid={touched[attribute.id] && !!errors[attribute.id]}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faAddressBook}/>
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      ))}
                    </div>
                    <div className="col-4">
                      {serverData.attributes && serverData.attributes.slice(Math.ceil(serverData.attributes.length/2), serverData.attributes.length).map((attribute: any) => (
                        <div className="mb-3" key={attribute.id}>
                          <InputGroup className="mb-3">
                            <Form.Control
                              id={attribute.id}
                              name={attribute.id}
                              type="text"
                              placeholder={attribute.value}
                              onChange={handleChange}
                              value={values[attribute.id]}
                              isValid={touched[attribute.id] && !errors[attribute.id]}
                              isInvalid={touched[attribute.id] && !!errors[attribute.id]}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faAddressBook}/>
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="text-center">
                    <Link to={{pathname: groupuuid ? `/group/view/${groupuuid}` : "/contact"}} className="btn btn-outline-danger">
                      <span>{t('views.label.cancel')}</span>
                    </Link>
                    {' '}
                    <Button
                      type="submit"
                      theme="outline-primary"
                      isLoading={isAddLoading}
                    >
                      {t('views.crud.add')}
                    </Button>
                  </div>

                </form>
              </div>
              <div className="card-footer">&nbsp;</div>
            </div>
          </div>
        </section>
      </div>
    );
  }
  return template;
};

export default ContactCreate;
