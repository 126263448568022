import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {toggleControlSidebar, toggleSidebarMenu} from '@app/store/reducers/ui';
import {Button} from '@app/components';
import MessagesDropdown from '@app/modules/main/header/messages-dropdown/MessagesDropdown';
import NotificationsDropdown from '@app/modules/main/header/notifications-dropdown/NotificationsDropdown';
import LanguagesDropdown from '@app/modules/main/header/languages-dropdown/LanguagesDropdown';
import UserDropdown from '@app/modules/main/header/user-dropdown/UserDropdown';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';

const Header = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navbarVariant = useSelector((state: any) => state.ui.navbarVariant);
  const headerBorder = useSelector((state: any) => state.ui.headerBorder);
  const user = useSelector((state: any) => state.auth.currentUser);

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  const handleToggleControlSidebar = () => {
    dispatch(toggleControlSidebar());
  };

  const getContainerClasses = useCallback(() => {
    let classes = `main-header navbar navbar-expand ${navbarVariant}`;
    if (headerBorder) {
      classes = `${classes} border-bottom-0`;
    }
    return classes;
  }, [navbarVariant, headerBorder]);

  return (
    <nav className={getContainerClasses()}>
      <ul className="navbar-nav">
        <li className="nav-item">
          <button
            onClick={handleToggleMenuSidebar}
            type="button"
            className="nav-link"
          >
            <i className="fas fa-bars" />
          </button>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <Link to="/" className="nav-link">
            <i className="fas fa-chart-pie" aria-hidden="true" />&nbsp;
            {t('header.label.dashboard')}
          </Link>
        </li>
        <Navbar.Collapse id="navbar-contacts" className="nav-item d-none d-sm-inline-block">
          <Nav>
            <NavDropdown
              id="nav-dropdown-contacts"
              title={<span><i className="fas fa-book" aria-hidden="true" /> {t('header.label.contacts')}</span>}
            >
              <Link to="/contact" className="dropdown-item">
                {t('views.contact.manage')}
              </Link>
              <NavDropdown.Divider />
              <Link to="/group" className="dropdown-item">
                {t('views.group.manage')}
              </Link>
              <NavDropdown.Divider />
              <Link to="/contact/import" className="dropdown-item">
                {t('views.contact.import')}
              </Link>
              <NavDropdown.Divider />
              <Link to="/contact/attribute" className="dropdown-item">
                {t('views.contact.attributes')}
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse id="navbar-sms" className="nav-item d-none d-sm-inline-block">
          <Nav>
            <NavDropdown
              id="nav-dropdown-sms"
              title={<span><i className="fas fa-envelope" aria-hidden="true" /> {t('header.label.sms')}</span>}
            >
              <Link to="/sms" className="dropdown-item">
                {t('views.sms.send')}
              </Link>
              <NavDropdown.Divider />
              <Link to="/sms/templates" className="dropdown-item">
                {t('views.sms.draft')}
              </Link>
              <NavDropdown.Divider />
              <Link to="/sms/file_schedule" className="dropdown-item">
                {t('views.sms.file2sms')}
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse id="navbar-payments" className="nav-item d-none d-sm-inline-block">
          <Nav>
            <NavDropdown
              id="nav-dropdown-payments"
              title={<span><i className="fas fa-money-bill-wave" aria-hidden="true" /> {t('header.label.payments')}</span>}
            >
              <Link to="/payments/recharge" className="dropdown-item">
                {t('views.payment.recharge')}
              </Link>
              <NavDropdown.Divider />
              <Link to="/payments" className="dropdown-item">
                {t('views.payment.history')}
              </Link>
              <NavDropdown.Divider />
              <Link to="/payments/transfer" className="dropdown-item">
                {t('views.payment.transfer')}
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse id="navbar-reports" className="nav-item d-none d-sm-inline-block">
          <Nav>
            <NavDropdown
              id="nav-dropdown-reports"
              title={<span><i className="fas fa-chart-bar" aria-hidden="true" /> {t('header.label.report')}</span>}
            >
              <Link to="/report" className="dropdown-item">
                {t('views.report.history')}
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        {/* TODO: remove direct access of seeing admin menu ...  */}
        {(user.is_admin || true) && (
          <Navbar.Collapse id="navbar-admin" className="nav-item d-none d-sm-inline-block">
            <Nav>
              <NavDropdown
                id="nav-dropdown-admin"
                title={<span><i className="fas fa-user-tie" aria-hidden="true" /> {t('header.label.admin')}</span>}
              >
                <Link to="/admin" className="dropdown-item">
                  {t('views.admin.dashboard')}
                </Link>
                <NavDropdown.Divider />
                <Link to="/admin/customers" className="dropdown-item">
                  {t('views.admin.customers')}
                </Link>
                <NavDropdown.Divider />
                <Link to="/admin/sender" className="dropdown-item">
                  {t('views.admin.sender')}
                </Link>
                <NavDropdown.Divider />
                <Link to="/admin/report" className="dropdown-item">
                  {t('views.admin.report')}
                </Link>
                <NavDropdown.Divider />
                <Link to="/admin/user" className="dropdown-item">
                  {t('views.admin.user')}
                </Link>
                <NavDropdown.Divider />
                <Link to="/admin/system" className="dropdown-item">
                  {t('views.admin.system')}
                </Link>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        )}
      </ul>
      <ul className="navbar-nav ml-auto">
        <MessagesDropdown />
        <NotificationsDropdown />
        <LanguagesDropdown />
        <UserDropdown />
        <li className="nav-item">
          <Button className="nav-link" onClick={handleToggleControlSidebar}>
            <i className="fas fa-cogs fa-2x" />
          </Button>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
