import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Dropdown} from '@components';
import {useSelector} from "react-redux";

const NotificationsDropdown = () => {
  const [t] = useTranslation();
  const user = useSelector((state: any) => state.auth.currentUser);

  return (
    <Dropdown
      isOpen={false}
      size="lg"
      buttonTemplate={
        <>
          <i className="far fa-bell fa-2x" />
          <span className="badge badge-info navbar-badge even-larger-badge">{user.notifications && user.notifications.filter((attribute: any) => attribute.read_at === null).length || ""}</span>
        </>
      }
      menuTemplate={
        <>
          <span className="dropdown-item dropdown-header">
            {t('header.notifications.count', {quantity: user.notifications && user.notifications.filter((attribute: any) => attribute.read_at === null).length})}
          </span>
          <div className="dropdown-divider" />
          {user.notifications && user.notifications.map((option: any) => (
            <div  key={option.id}>
              <Link to="/" className="dropdown-item">
                <i className="fas fa-info fa-fw mr-2" />
                <span>
                  {option.data.title.slice(0, 23)}
                </span>
                <span className="float-right text-muted text-sm">
                  {option.created}
                </span>
              </Link>
              <div className="dropdown-divider" />
            </div>
          ))}
          <Link to="/" className="dropdown-item dropdown-footer">
            {t('header.notifications.seeAll')}
          </Link>
        </>
      }
    />
  );
};

export default NotificationsDropdown;
