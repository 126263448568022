import React, {ChangeEventHandler, FormEventHandler, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import apiRequest from "@app/utils/axios";
import {toast} from "react-toastify";
import {loadPageData} from "@store/reducers/auth";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, DatatableRemote, InputText} from "@components";
import {faBook} from "@fortawesome/free-solid-svg-icons";

const ContactGroupShow = () => {
  const { groupuuid } = useParams();
  const [t] = useTranslation();
  const [isUpdateLoading, setUpdateLoading] = useState(false);
  const serverData = useSelector((state: any) => state.auth.data);
  const dispatch = useDispatch();
  const columns = [
    {
      name: t('views.contact.first_name'),
      selector: (row: any) => row.first_name,
    },
    {
      name: t('views.contact.last_name'),
      selector: (row: any) => row.last_name,
    },
    {
      name: t('views.contact.phone_number'),
      selector: (row: any) => row.phone_number,
    },
    {
      name: t('views.contact.status'),
      cell: (cols: any) => <span className="badge badge-pill badge-info">{cols.issubscribed ? t('views.contact.subscribed') : t('views.contact.created')}</span>,
    },
    {
      name: t('views.button.action'),
      cell: (cols: any) => <Link
        to={{pathname:`/contact/edit/${cols.uuid}`}}
        state={{groupuuid}}
        className="btn btn-sm btn-send-sms"
      >
        <i className="fa fa-pencil-alt"/> {t('views.crud.edit')}
      </Link>,
    },
  ];
  const UpdateContactGroup = async (values: object, actions: any) => {
    setUpdateLoading(true);
    actions.setSubmitting(true);
    await apiRequest(`contact/post_group_update/${groupuuid}`, (response: any) => {
      setUpdateLoading(false);
      actions.setSubmitting(false);
      if (response.status === 0) {
        toast.success(response.message);
      } else if (response.status === 3) {
        toast.error(response.message);
      }
    }, values, (error: any) => {
      if (error.response.status === 422) {
        actions.setErrors(error.response.data.errors);
      }
      setUpdateLoading(false);
      actions.setSubmitting(false);
    });
  };
  const {handleChange, values, handleSubmit, touched, errors}: {handleChange: ChangeEventHandler, values: any, handleSubmit: FormEventHandler<any>, touched: any, errors: any} = useFormik({
    initialValues: {
      'group': serverData.group && serverData.group.group,
      'description': serverData.group && serverData.group.description
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      group: Yup.string()
        .required(t('validation.required')),
    }),
    onSubmit: UpdateContactGroup,
  });
  useEffect( () => {
    apiRequest(`contact/group_info/${groupuuid}`, (response: any) => {
      dispatch(loadPageData(response));
    });
    return () => {
    };
  }, []);

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">{t('views.group.info')}</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus" />
                </button>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="remove"
                  data-toggle="tooltip"
                  title="Remove"
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <p>{t('views.group.edit')}</p>
              <div className="row">
                <div className="col-12">
                  <form onSubmit={handleSubmit} className="form-inline mb-3">
                    <div className="form-group mx-sm-2">
                      <InputText
                        title={t('views.group.name')}
                        name="group"
                        handleChange={handleChange}
                        value={values.group}
                        touched={touched.group}
                        error={errors.group}
                        icon={faBook}
                      />
                    </div>
                    <div className="form-group mx-sm-1">
                      <InputText
                        title={t('views.group.description')}
                        name="description"
                        handleChange={handleChange}
                        value={values.description}
                        touched={touched.description}
                        error={errors.description}
                        icon={faBook}
                      />
                    </div>
                    <Button
                      type="submit"
                      theme="outline-primary"
                      isLoading={isUpdateLoading}
                      className="mb-3"
                    >
                      {t('views.crud.update')}
                    </Button>
                  </form>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <div className="process">
                    <div className="process-row">
                      <div className="process-step">
                        <Link to={{pathname:"/contact/add", }}
                              className="btn btn-outline-secondary btn-circle"
                              state={{groupuuid}}
                        >
                          <i className="fa fa-user-plus fa-3x mt-3"/>
                        </Link>
                        <p>{t('views.group.acontact')}</p>
                      </div>
                      <div className="process-step">
                        <Link
                          to={{pathname:"/contact/import"}}
                          className="btn btn-outline-secondary btn-circle"
                          state={{groupuuid, groupname:serverData.group && serverData.group.group}}
                        >
                          <i className="fa fa-folder-open fa-3x mt-3"/>
                        </Link>
                        <p>{t('views.group.import')}</p>
                      </div>
                      <div className="process-step">
                        <Link
                          to={{pathname:"/contact"}}
                          state={{groupuuid, groupname:serverData.group && serverData.group.group}}
                          className="btn btn-outline-secondary btn-circle"
                        >
                          <i className="fas fa-upload fa-3x mt-3"/>
                        </Link>
                        <p>{t('views.group.directory')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <DatatableRemote
                    title="Contacts List"
                    columns={columns}
                    params={{"datatable": `contact/filter_group_list/${groupuuid}`,"delete":`contact/post_remove/${groupuuid}`}}
                    buttonSelector="contact_group_contacts"
                  />
                </div>
              </div>

            </div>
            <div className="card-footer">&nbsp;</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactGroupShow;
