import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useFormik} from 'formik';
/* import GoogleLogin from 'react-google-login'; */
import { GoogleLogin } from '@react-oauth/google';
import {useTranslation} from 'react-i18next';
import {loginUser, unConfirmRegistration, completeRegistration} from '@store/reducers/auth';
import {Checkbox, Button} from '@components';
import {faKey, faUser} from '@fortawesome/free-solid-svg-icons';
import {removeWindowClass, setWindowClass} from '@app/utils/helpers';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Title from "@modules/guest/Title";
import {httpHostname} from '@app/utils/settings';
import Header from "@modules/guest/Header";
import * as Yup from 'yup';
import {Form, InputGroup} from 'react-bootstrap';
import apiRequest from "@app/utils/axios";

const Login = () => {
  const [isAuthLoading, setAuthLoading] = useState(false);
  const [isGoogleAuthLoading, setGoogleAuthLoading] = useState(false);
  const needGoogleAuth = (process.env.REACT_APP_RESELLER_URL === httpHostname || process.env.REACT_APP_CUSTOMER_URL === httpHostname);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [t] = useTranslation();

  const login = async (username: string, password: string) => {
    setAuthLoading(true);
    await apiRequest(`user/login`, (response: any) => {
      setAuthLoading(false);
      if (response.status === 0) {
        toast.success(response.message);
        removeWindowClass('login-page');
        removeWindowClass('hold-transition');
        dispatch(loginUser({'token': response.uuid}));
        navigate(`/`);
      } else if (response.status === 33) {
        toast.error(response.message);
        dispatch(unConfirmRegistration());
        navigate(`/confirm-registration/${response.user_uuid}`);
      } else {
        toast.error(response.message);
      }
    }, {username, password}, () => {
      setAuthLoading(false);
    });
  };

  /* const loginByGoogle: any = useGoogleOneTapLogin({
    onSuccess: async (response: any) => {
      console.log(response)
      await apiRequest(`user/login_provider`, (response: any) => {
        setGoogleAuthLoading(false);
        if (response.status === 0) {
          toast.success(response.message);
          removeWindowClass('login-page');
          removeWindowClass('hold-transition');
          dispatch(loginUser({'token': response.uuid}));
          navigate(`/`);
        } else if (response.status === 33) {
          toast.error(response.message);
          dispatch(unConfirmRegistration());
          navigate(`/confirm-registration/${response.user_uuid}`);
        } else if (response.status === 34) {
          toast.success(response.message);
          dispatch(completedRegistration());
          navigate(`/complete-registration/${response.user_uuid}`);
        } else {
          toast.error(response.message);
        }
      }, {token: response.access_token}, () => {
        setGoogleAuthLoading(false);
      });
    },
    onError: () => console.log('Login Failed'),
  }); */

  const loginByGoogle = async (response: any) => {
    setGoogleAuthLoading(true);
    /* console.log(response); */
    await apiRequest(`user/login_provider`, (response: any) => {
      setGoogleAuthLoading(false);
      if (response.status === 0) {
        toast.success(response.message);
        removeWindowClass('login-page');
        removeWindowClass('hold-transition');
        dispatch(loginUser({'token': response.user_uuid}));
        navigate(`/`);
      } else if (response.status === 33) {
        toast.error(response.message);
        dispatch(unConfirmRegistration());
        navigate(`/confirm-registration/${response.user_uuid}`);
      } else if (response.status === 34) {
        toast.success(response.message);
        dispatch(completeRegistration());
        navigate(`/complete-registration/${response.user_uuid}`);
      } else {
        toast.error(response.message);
      }
    }, {token: response.credential}, () => {
      setGoogleAuthLoading(false);
    });
  };

  const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(3, t('validation.minimum', {mincount: '3'}))
        .required(t('validation.required')),
      password: Yup.string()
        .min(6, t('validation.minimum', {mincount: '6'}))
        .max(20, t('validation.maximum', {maxcount: '20'}))
        .required(t('validation.required'))
    }),
    onSubmit: (values) => {
      login(values.username, values.password);
    }
  });

  setWindowClass('hold-transition login-page');

  return (
    <>
      <Header />
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <Title />
          </div>
          <div className="card-body">
            <p className="login-box-msg">{t('login.label.signIn')}</p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <InputGroup className="mb-3">
                  <Form.Control
                    id="username"
                    name="username"
                    type="text"
                    placeholder={t('login.label.username')}
                    onChange={handleChange}
                    value={values.username}
                    isValid={touched.username && !errors.username}
                    isInvalid={touched.username && !!errors.username}
                  />
                  {touched.username && errors.username ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.username}
                    </Form.Control.Feedback>
                  ) : (
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  )}
                </InputGroup>
              </div>
              <div className="mb-3">
                <InputGroup className="mb-3">
                  <Form.Control
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={handleChange}
                    value={values.password}
                    isValid={touched.password && !errors.password}
                    isInvalid={touched.password && !!errors.password}
                  />
                  {touched.password && errors.password ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  ) : (
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faKey} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  )}
                </InputGroup>
              </div>

              <div className="row">
                <div className="col-12">
                  <Button
                    block
                    theme="outline-primary"
                    type="submit"
                    isLoading={isAuthLoading}
                    disabled={isGoogleAuthLoading}
                  >
                    {/* @ts-ignore */}
                    {t('login.button.signIn.label')}
                  </Button>
                </div>
              </div>
              <div className="row mb-2 mt-3">
                <div className="col-6">
                  <Checkbox type="icheck" name="remember" checked={false}>
                    {t('login.label.rememberMe')}
                  </Checkbox>
                </div>
                <div className="col-6">
                  <Link to="/forgot-password" className="float-right">{t('login.label.forgotPass')}</Link>
                </div>
              </div>
            </form>
            <p className="mb-0">
              <Link to="/register" className="text-center">
                {t('login.label.registerNew')}
              </Link>
            </p>
            {/* TODO: remove direct access of google authentication ...  */}
            {(needGoogleAuth || true) &&
            <div className="social-auth-links text-center mt-2 mb-3">
              {/* <GoogleLogin
              clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
              buttonText={t('login.button.signIn.social', {what: 'Google'})}
              theme='dark'
              disabled={isAuthLoading || isGoogleAuthLoading}
              onSuccess={loginByGoogle}
              onFailure={loginByGoogle}
              cookiePolicy='single_host_origin'
            /> */}
              {/* <Button
                block
                icon="google"
                theme="outline-danger"
                onClick={() => loginByGoogle()}
                isLoading={isGoogleAuthLoading}
              >
                {t('login.button.signIn.social', {what: 'Google'})}
              </Button> */}
              <GoogleLogin
                onSuccess={loginByGoogle}
                onError={() => {
                  console.log('Login Failed');
                }}
                useOneTap
              />
              {/* <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
              </GoogleOAuthProvider> */}
            </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
