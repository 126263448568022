import React, {FunctionComponent} from 'react';
import {getCountries, getCountryCallingCode} from 'react-phone-number-input/input'
import {CountryCode} from "libphonenumber-js/types"
import {Locale} from '@components'
import Select from "react-select";
/* import {Select} from '@app/components'; */

type DropDownProps = {
  value: CountryCode;
  onChange: (selectedOption: any) => void;
  width?: string;
};
const CountrySelect: FunctionComponent<DropDownProps> = ({value, onChange, width = "100%", ...rest }) => (
     <Select
        styles={{control: (styles) => ({...styles, width, float: 'right'})}}
        name="code"
        defaultValue={value && {'value': value, 'label': `${Locale[value]} (+${getCountryCallingCode(value)})`}}
        options={getCountries().map((country) => ({
            'value': country,
            'label': `${Locale[country]} (+${getCountryCallingCode(country)})`
        }))}
        onChange={onChange}
        {...rest}
    />
);

export default CountrySelect;
