import {LineChart, SmallBox} from '@app/components';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import apiRequest from "@app/utils/axios";
import {useTranslation} from "react-i18next";
import {loadPageData} from "@store/reducers/auth";
import {Link} from "react-router-dom";

const Dashboard = () => {
  const serverData = useSelector((state: any) => state.auth.data);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const fetchDashboardData = () => {
    apiRequest(`user/dashboard`, (response: any) => {
      dispatch(loadPageData(response));
    });
  };
  useEffect(() => {
    fetchDashboardData();
    return () => {
    };
  }, []);
  return (
    <div className="container-fluid">
      {/* <h5 className="mb-2">^Directory Data</h5>
      <div className="row">
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box">
            <span className="info-box-icon bg-info">
              <i className="far fa-envelope" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">^Contacts</span>
              <span className="info-box-number">1,410</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box">
            <span className="info-box-icon bg-success">
              <i className="far fa-flag" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Bookmarks</span>
              <span className="info-box-number">410</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box">
            <span className="info-box-icon bg-warning">
              <i className="far fa-copy" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Uploads</span>
              <span className="info-box-number">13,648</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box">
            <span className="info-box-icon bg-danger">
              <i className="far fa-star" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Likes</span>
              <span className="info-box-number">93,139</span>
            </div>
          </div>
        </div>
      </div>

      <h5 className="mb-2">
        Info Box With Custom Shadows{' '}
        <small>
          <i>Using Bootstraps Shadow Utility</i>
        </small>
      </h5>
      <div className="row">
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box shadow-none">
            <span className="info-box-icon bg-info">
              <i className="far fa-envelope" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Shadows</span>
              <span className="info-box-number">None</span>
            </div>
          </div>
           /.info-box
        </div>
         /.col
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box shadow-sm">
            <span className="info-box-icon bg-success">
              <i className="far fa-flag" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Shadows</span>
              <span className="info-box-number">Small</span>
            </div>
             /.info-box-content
          </div>
           /.info-box
        </div>
         /.col
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box shadow">
            <span className="info-box-icon bg-warning">
              <i className="far fa-copy" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Shadows</span>
              <span className="info-box-number">Regular</span>
            </div>
             /.info-box-content
          </div>
           /.info-box
        </div>
         /.col
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box shadow-lg">
            <span className="info-box-icon bg-danger">
              <i className="far fa-star" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Shadows</span>
              <span className="info-box-number">Large</span>
            </div>
             /.info-box-content
          </div>
           /.info-box
        </div>
         /.col
      </div> */}

      {/* <h5 className="mt-4 mb-2">
        Info Box With <code>bg-*</code>
      </h5>
      <div className="row">
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box bg-info">
            <span className="info-box-icon">
              <i className="far fa-bookmark" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Bookmarks</span>
              <span className="info-box-number">41,410</span>

              <div className="progress">
                <div className="progress-bar" style={{width: '70%'}} />
              </div>
              <span className="progress-description">
                70% Increase in 30 Days
              </span>
            </div>

          </div>

        </div>

        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box bg-success">
            <span className="info-box-icon">
              <i className="far fa-thumbs-up" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Likes</span>
              <span className="info-box-number">41,410</span>

              <div className="progress">
                <div className="progress-bar" style={{width: '70%'}} />
              </div>
              <span className="progress-description">
                70% Increase in 30 Days
              </span>
            </div>
             /.info-box-content
          </div>
           /.info-box
        </div>
         /.col
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box bg-warning">
            <span className="info-box-icon">
              <i className="far fa-calendar-alt" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Events</span>
              <span className="info-box-number">41,410</span>

              <div className="progress">
                <div className="progress-bar" style={{width: '70%'}} />
              </div>
              <span className="progress-description">
                70% Increase in 30 Days
              </span>
            </div>
             /.info-box-content
          </div>
           /.info-box
        </div>
         /.col
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box bg-danger">
            <span className="info-box-icon">
              <i className="fas fa-comments" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Comments</span>
              <span className="info-box-number">41,410</span>

              <div className="progress">
                <div className="progress-bar" style={{width: '70%'}} />
              </div>
              <span className="progress-description">
                70% Increase in 30 Days
              </span>
            </div>
             /.info-box-content
          </div>
           /.info-box
        </div>
         /.col
      </div> */}

      {/* <h5 className="mt-4 mb-2">
        Info Box With <code>bg-gradient-*</code>
      </h5>
      <div className="row">
        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box bg-gradient-info">
            <span className="info-box-icon">
              <i className="far fa-bookmark" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Bookmarks</span>
              <span className="info-box-number">41,410</span>

              <div className="progress">
                <div className="progress-bar" style={{width: '70%'}} />
              </div>
              <span className="progress-description">
                70% Increase in 30 Days
              </span>
            </div>

          </div>

        </div>

        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box bg-gradient-success">
            <span className="info-box-icon">
              <i className="far fa-thumbs-up" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Likes</span>
              <span className="info-box-number">41,410</span>

              <div className="progress">
                <div className="progress-bar" style={{width: '70%'}} />
              </div>
              <span className="progress-description">
                70% Increase in 30 Days
              </span>
            </div>

          </div>

        </div>

        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box bg-gradient-warning">
            <span className="info-box-icon">
              <i className="far fa-calendar-alt" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Events</span>
              <span className="info-box-number">41,410</span>

              <div className="progress">
                <div className="progress-bar" style={{width: '70%'}} />
              </div>
              <span className="progress-description">
                70% Increase in 30 Days
              </span>
            </div>

          </div>

        </div>

        <div className="col-md-3 col-sm-6 col-12">
          <div className="info-box bg-gradient-danger">
            <span className="info-box-icon">
              <i className="fas fa-comments" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">Comments</span>
              <span className="info-box-number">41,410</span>

              <div className="progress">
                <div className="progress-bar" style={{width: '70%'}} />
              </div>
              <span className="progress-description">
                70% Increase in 30 Days
              </span>
            </div>

          </div>

        </div>

      </div> */}


      {/* Small Box (Stat card) */}
      {/* <h5 className="mb-2 mt-4">Small Box</h5> */}
      <div className="row">
        <div className="col-lg-3 col-6">
          <div className={`info-box ${serverData.volumeavgdiff > 50 ? "bg-gradient-success" : "bg-gradient-danger"}`}>
            <span className="info-box-icon">
              <i className="fas fa-chart-bar" />
            </span>

            <div className="info-box-content">
              <span className="info-box-text">{t('views.dashboard.volumeavg')}</span>
              <span className="info-box-number">{serverData.volumeavg || 0}</span>

              <div className="progress">
                <div className="progress-bar" style={{width: serverData.volumeavgdiff}} />
              </div>
              <span className="progress-description">
                {serverData.volumeavgdiff ? Math.abs(serverData.volumeavgdiff) : 0}
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-6">
          <SmallBox
            type="success"
            icon="ion-paper-airplane"
            count={serverData.sent || 0}
            title={t('views.dashboard.sent')}
            navigateTo="/report/recipients"
          />
        </div>
        <div className="col-lg-3 col-6">
          <SmallBox
            type="gradient-secondary"
            count={serverData.scheduled || 0}
            title={t('views.dashboard.scheduled')}
            icon="ion-clock"
            navigateTo="/report/scheduled"
          />
        </div>
        <div className="col-lg-3 col-6">
          <SmallBox
            type="danger"
            count={serverData.failed || 0}
            title={t('views.dashboard.failed')}
            icon="ion-android-alert"
            navigateTo="/report/failed"
          />
        </div>
      </div>
      <hr/>
      <div className="row">
        <div className="col-lg-3 col-3">
          <h2>{t('views.dashboard.welcome1')}</h2>
          <p>{t('views.dashboard.welcome2')}</p>
          <Link to="/get_started" className="btn btn-secondary">
              <span className="mr-2">{t('views.dashboard.getstarted')}</span>
              <i className="fa fa-arrow-circle-right"/>
          </Link>
        </div>
        <div className="col-lg-9 col-9">
          <h5 className="text-center">{t('views.dashboard.volume')}</h5>
          <div className="text-center"><LineChart data={serverData.volume || []} xdatakey="month" linekey="volume"/></div>
        </div>
      </div>


      {/* <div className="row">
        <div className="col-lg-3 col-6">

          <div className="small-box bg-info">
            <div className="overlay">
              <i className="fas fa-3x fa-sync-alt" />
            </div>

            <div className="inner">
              <h3>150</h3>

              <p>New Orders</p>
            </div>
            <div className="icon">
              <i className="fas fa-shopping-cart" />
            </div>
            <a href="/" className="small-box-footer">
              More info <i className="fas fa-arrow-circle-right" />
            </a>
          </div>
        </div>

        <div className="col-lg-3 col-6">

          <div className="small-box bg-success">
            <div className="overlay dark">
              <i className="fas fa-3x fa-sync-alt" />
            </div>

            <div className="inner">
              <h3>
                53<sup style={{fontSize: '20px'}}>%</sup>
              </h3>

              <p>Bounce Rate</p>
            </div>
            <div className="icon">
              <i className="ion ion-stats-bars" />
            </div>
            <a href="/" className="small-box-footer">
              More info <i className="fas fa-arrow-circle-right" />
            </a>
          </div>
        </div>

      </div> */}

    </div>
  );
};

export default Dashboard;
