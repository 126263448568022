import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {useWindowSize} from '@app/hooks/useWindowSize';
import {calculateWindowSize} from '@app/utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {setWindowSize} from '@app/store/reducers/ui';
import {fetchRequest} from '@app/utils/axios';
import {loadCSRF} from '@store/reducers/auth';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import Register from '@modules/register/Register';
import CompleteRegistration from "@modules/register/CompleteRegistration";
import ConfirmRegistration from "@modules/register/ConfirmRegistration";
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import ContactIndex from '@modules/main/contact/Index';
import ContactCreate from '@modules/main/contact/Create';
import EditContact from "@modules/main/contact/Edit";
import AddContactToGroup from "@modules/main/contact/AddToGroup";
import ManageAttribute from "@modules/main/contact/ManageAttribute";
import ContactGroupIndex from '@modules/main/group/Index';
import ContactGroupShow from '@modules/main/group/Show';
import ImportContactFile from "@modules/main/contact/ImportFile";
// import ComposeSms from "@modules/main/sms/Compose";
import CreateSmsDraft from "@modules/main/sms/draft/Create";
import EditSmsDraft from "@modules/main/sms/draft/Edit";
import ShowSmsDraft from "@modules/main/sms/draft/Show";
import ListSmsDraft from "@modules/main/sms/draft/Index";
import Dashboard from '@pages/Dashboard';
import Blank from '@pages/Blank';
// import SubMenu from '@pages/SubMenu';
import Profile from '@pages/profile/Profile';
import ConfirmRegistrationRoute from "@app/routes/ConfirmRegistrationRoute";
import CompleteRegistrationRoute from "@app/routes/CompleteRegistrationRoute";
import AdminRoute from "@app/routes/AdminRoute";
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';

const App = () => {
  const windowSize = useWindowSize();
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchRequest('user/csrf', (response: object) => {
      dispatch(loadCSRF(response));
    });
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/register" element={<PublicRoute />}>
          <Route path="/register" element={<Register />} />
        </Route>
        <Route path="/confirm-registration/:useruuid" element={<ConfirmRegistrationRoute />}>
          <Route path="/confirm-registration/:useruuid" element={<ConfirmRegistration />} />
        </Route>
        <Route path="/complete-registration/:useruuid" element={<CompleteRegistrationRoute />}>
          <Route path="/complete-registration/:useruuid" element={<CompleteRegistration />} />
        </Route>
        <Route path="/forgot-password" element={<PublicRoute />}>
          <Route path="/forgot-password" element={<ForgetPassword />} />
        </Route>
        <Route path="/recover-password/:uid/:token" element={<PublicRoute />}>
          <Route path="/recover-password/:uid/:token" element={<RecoverPassword />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Main />}>
            <Route path="/" element={<Dashboard />} />
            {/* <Route path="/sub-menu-2" element={<Blank />} /> */}
            {/* <Route path="/sub-menu-1" element={<SubMenu />} /> */}
            <Route path="/blank" element={<Blank />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/get_started" element={<Profile />} />
            <Route path="/contact">
              <Route path="/contact" element={<ContactIndex />} />
              <Route path="/contact/add" element={<ContactCreate />} />
              <Route path="/contact/togroup" element={<AddContactToGroup />} />
              <Route path="/contact/edit/:editId" element={<EditContact />} />
              <Route path="/contact/import" element={<ImportContactFile />} />
              <Route path="/contact/attribute" element={<ManageAttribute />} />
            </Route>
            <Route path="/group">
              <Route path="/group" element={<ContactGroupIndex />} />
              <Route path="/group/view/:groupuuid" element={<ContactGroupShow />} />
            </Route>
            <Route path="/sms">
              <Route path="/sms" element={<Blank />} />
              <Route path="/sms/draft" element={<ListSmsDraft />} />
              <Route path="/sms/create" element={<CreateSmsDraft />} />
              <Route path="/sms/draft/show" element={<ShowSmsDraft />} />
              <Route path="/sms/draft/edit/:editId" element={<EditSmsDraft />} />
              <Route path="/sms/sent" element={<Blank />} />
              <Route path="/sms/view" element={<Blank />} />
              <Route path="/sms/sent/:smsId" element={<Blank />} />
              <Route path="/sms/file_schedule" element={<Blank />} />
            </Route>
            <Route path="/payments" element={<Blank />}>
              <Route path="/payments/transfer" element={<Blank />} />
              <Route path="/payments/recharge" element={<Blank />} />
            </Route>
            <Route path="/report" element={<Blank />}>
              <Route path="/report/recipients" element={<Blank />} />
              <Route path="/report/scheduled" element={<Blank />} />
              <Route path="/report/failed" element={<Blank />} />
            </Route>
            <Route path="/admin" element={<AdminRoute />}>
              <Route path="/admin" element={<Blank />}>
                <Route path="/admin/customers" element={<Blank />} />
                <Route path="/admin/sender" element={<Blank />} />
                <Route path="/admin/report" element={<Blank />} />
                <Route path="/admin/user" element={<Blank />} />
                <Route path="/admin/system" element={<Blank />} />
              </Route>
          </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
