import React from 'react';
import classes from './PageLoading.module.scss';

const PageLoading = () => {
    return (
        <div className={classes.loading}>
            <span>K</span>
            <span>A</span>
            <span>R</span>
            <span>I</span>
            <span>B</span>
            <span>U</span>
            <span>.</span>
            <span>.</span>
        </div>
    );
};

export default PageLoading;
