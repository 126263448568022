import React from "react";
import {useSelector} from "react-redux";

const Header = () => {
  const logo = useSelector((state: any) => state.auth.logo);
  return (
    <>
      {logo ? <img
        src={logo}
        alt="Logo"
        style={{opacity: '.8', height: '10%'}}
      /> : ""}
    </>
  );
};

export default Header;
