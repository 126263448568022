import apiRequest from '@app/utils/axios';

export const selectCodeValue = (inputValue: string, codeId: number): Promise<any> => {
    return apiRequest(`sysdef/code_values/${codeId}`, (response: object) => {
      return response;
    });
};

export const searchContacts = (inputValue: string): Promise<any> => {
  return apiRequest(`contact/directory_search`, (response: object) => {
    return response;
  }, {query_string: inputValue});
};
