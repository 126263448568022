import React, {ChangeEventHandler} from "react";
import {Form} from "react-bootstrap";

export interface InputTextProps {
  title: string;
  name: string;
  handleChange: ChangeEventHandler;
  handleClick?: any;
  touched: boolean;
  error: string,
  radios: any;
  value?: any;
}

const InputRadio = ({title, name, radios, value="", touched, error, handleChange, handleClick = () => {}}: InputTextProps) => {
  return (
    <>
      <div key="inline-radio" className="mb-3">
        <div>{title}</div>
        {radios.map((radio: any, idx: number) => (
          <Form.Check
            inline
            label={radio.name}
            name={name}
            type="radio"
            value={radio.value}
            onChange={handleChange}
            onClick={handleClick}
            id={`${name}-inline-radio-${idx}`}
            key={`${name}_${radio.value}`}
            defaultChecked={value === radio.value}
          />
        ))}
        {(touched && error) && (
          <Form.Control.Feedback type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </div>
    </>
  );
};

export default InputRadio;
